import React from 'react'
import {createStore, compose, applyMiddleware} from "redux"
import {rootReducer} from "./store/rootReducer"
import thunk from "redux-thunk"
import {Provider} from "react-redux"
import {BrowserRouter} from "react-router-dom"
import {render} from 'react-dom'
import App from './App'
import './index.css'
import {CBM_APP_VERSION} from "./constants/appConstants";


if (localStorage.getItem('cbm_app_version') !== CBM_APP_VERSION) {
    localStorage.removeItem('cbm')
    localStorage.setItem('cbm_app_version', CBM_APP_VERSION)
}

const reduxDebugger = (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose
const store = createStore(rootReducer, compose(applyMiddleware(thunk), reduxDebugger))
const appWithRouter = <BrowserRouter><App/></BrowserRouter>
const appWithProvider = <Provider store={store}>{appWithRouter}</Provider>
const rootElement = document.getElementById('root')

render(appWithProvider, rootElement)
