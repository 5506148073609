import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {setActiveTab, setSettingData, updateField} from "../../store/actions"
import './ChangeLanguage.css'
import waveEffect from "../../helpers/waveEffect";
import {changeLanguage as translations} from "../../translations";


export default () => {
    const tabKey = 'changeLanguage'
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const tabData = useSelector(state => state.settings.temp[tabKey])

    useEffect(() => dispatch(setSettingData(tabKey, 'language', language)), [])

    const isCurrent = lang => lang === tabData.language
    const getClassName = lang => isCurrent(lang) ? 'change-language-button change-language-button--active' : 'change-language-button'

    const fieldChangeHandler = (fieldKey, value) => () => dispatch(setSettingData(tabKey, fieldKey, value))
    const saveButtonHandler = e => {
        waveEffect(e)
        if (language !== tabData.language) {
            dispatch(updateField('lang', tabData.language))
            localStorage.setItem('cbm_language', tabData.language)
            dispatch(setActiveTab(''))
        }
    }

    return (
        <>
            <div className="tab-section">
                <div className="change-language-buttons-wrapper">
                    <button className={getClassName('ru')} onClick={fieldChangeHandler('language', 'ru')}>
                        Русский
                    </button>
                    <span className="change-language-separator" />
                    <button className={getClassName('en')} onClick={fieldChangeHandler('language', 'en')}>
                        English
                    </button>
                    <span className="change-language-separator" />
                    <button className={getClassName('uk')} onClick={fieldChangeHandler('language', 'uk')}>
                        Українська
                    </button>
                </div>
            </div>
            <div className="tab-section">
                <button className="tab-button tab-button--save" onClick={saveButtonHandler}>
                    {translations[language].save}
                </button>
            </div>
        </>
    )
}