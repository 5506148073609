import './OtpSms.css'
import {useDispatch, useSelector} from "react-redux";
import getLanguage from "../../helpers/getLanguage";
import {otpSms as translations} from "../../translations";
import React, {useEffect, useRef, useState} from "react";
import _ from "../../helpers/_";
import Button from "../Button/Button";
import {acceptOtp, signInOtp, updateField} from "../../store/actions";

export default () => {
    const dispatch = useDispatch()
    const otpPhoneNumber = useSelector(state => state.app.otpPhoneNumber)
    const otpValid = useSelector(state => state.app.otpValid)
    const requestInProgress = useSelector(state => state.app.requestInProgress)
    const language = getLanguage()
    const texts = translations[language]
    const [otp1, setOtp1] = useState('');
    const [otp2, setOtp2] = useState('');
    const [otp3, setOtp3] = useState('');
    const [otp4, setOtp4] = useState('');
    const [timer, setTimer] = useState(59);

    const input1Ref = useRef()
    const input2Ref = useRef()
    const input3Ref = useRef()
    const input4Ref = useRef()
    const emptyRef = { current: null }

    const isShowTimer = parseInt(timer, 10) !== 0
    const isShowResendButton = parseInt(timer, 10) === 0

    const acceptOtpHandler = () => dispatch(acceptOtp(otp1 + otp2 + otp3 + otp4))
    const decreaseTimer = () => setTimeout(() => {
        const value = parseInt(timer, 10)
        const newValue = value - 1

        if (value > 0) {
            setTimer(String(newValue).length === 1 ? '0' + newValue : newValue)
        }
    }, 1000)

    useEffect(decreaseTimer, [timer])
    useEffect(() => {
        if (otpValid === false) {
            setOtp1('')
            setOtp2('')
            setOtp3('')
            setOtp4('')
            input1Ref.current && input1Ref.current.focus()
            setTimeout(() => dispatch(updateField('otpValid', true)), 3000)
        }
    }, [otpValid])
    useEffect(() => {
        if (!!(otp1 && otp2 && otp3 && otp4)) {
            acceptOtpHandler()
        }
    }, [otp1, otp2, otp3, otp4])

    /*
    * Handlers
    * */
    const otpInputChangeHandler = (setHandlers, refs) => e => {
        const value = e.target.value

        if (value === '') {
            setHandlers[0]('')
            return
        }

        if (/\D/g.test(value)) return

        if (/^\d+$/g.test(value)) {
            if (value.length === 1) {
                setHandlers[0](value)
                refs[1] && refs[1].current && refs[1].current.focus()
            } else {
                setHandlers.forEach((setHandler, i) => {
                    if (value[i]) {
                        setHandler(value[i])
                        refs[i].current && refs[i].current.focus()
                    }
                })
            }
        }
    }
    const otpInputKeyUpHandler = prevRef => e => {
        if (e.key === 'Backspace' ) {
            prevRef.current && prevRef.current.focus()
        }
    }
    const otpInputFocusHandler = e => {
        if (e.target.value) {
            e.target.setSelectionRange(0, 1)
        }
    }

    const resendCodeClickHandler = () => {
        dispatch(updateField('otpIsResend', true))
        dispatch(signInOtp())
        setTimer(59)
    }

    const nextStepClickHandler = acceptOtpHandler
    const prevStepClickHandler = () => dispatch(updateField('otpStageName', 'otp_enter_phone'))

    /*
    * Props
    * */
    const inputGeneralProps = {
        type: 'tel',
        className: otpValid ? 'otp-input' : 'otp-input otp-input--error',
        onFocus: otpInputFocusHandler
    }
    const input1Props = {
        onChange: otpInputChangeHandler([setOtp1, setOtp2, setOtp3, setOtp4], [input1Ref, input2Ref, input3Ref, input4Ref]),
        onKeyUp: otpInputKeyUpHandler(emptyRef),
        ref: input1Ref,
        value: otp1,
    }
    const input2Props = {
        onChange: otpInputChangeHandler([setOtp2, setOtp3, setOtp4], [input2Ref, input3Ref, input4Ref]),
        onKeyUp: otpInputKeyUpHandler(input1Ref),
        ref: input2Ref,
        value: otp2,
    }
    const input3Props = {
        onChange: otpInputChangeHandler([setOtp3, setOtp4], [input3Ref, input4Ref]),
        onKeyUp: otpInputKeyUpHandler(input2Ref),
        ref: input3Ref,
        value: otp3,
    }
    const input4Props = {
        onChange: otpInputChangeHandler([setOtp4],[input4Ref]),
        onKeyUp: otpInputKeyUpHandler(input3Ref),
        ref: input4Ref,
        value: otp4,
    }
    const resendCodeButtonProps = {
        onClick: resendCodeClickHandler,
        className: 'otp-resend-code-button'
    }
    const nextButtonProps = {
        fullWidth: true,
        active: !!(otp1 && otp2 && otp3 && otp4),
        loading: requestInProgress,
        onClick: nextStepClickHandler
    }
    const prevButtonProps = {
        fullWidth: true,
        type: 'outline',
        onClick: prevStepClickHandler
    }


    /*
    * Base return
    * */
    return (
        <div className="otp-sms-component">

            <div className="otp-sms-phone-text">
                {texts.smsWasSent(otpPhoneNumber)}
            </div>

            <div className="otp-inputs">
                <input { ...inputGeneralProps } { ...input1Props } />
                <input { ...inputGeneralProps } { ...input2Props } />
                <input { ...inputGeneralProps } { ...input3Props } />
                <input { ...inputGeneralProps } { ...input4Props } />
            </div>

            <div className="otp-cta-text">{texts.enterVerificationCode}</div>

            <div className="otp-resend-code-text">{texts.didNotReceive}</div>

            {_(isShowTimer,
            <div className="otp-timer">{texts.availableThrough(timer)}</div>)}

            {_(isShowResendButton,
            <button { ...resendCodeButtonProps } >{texts.resendCode}</button>)}

            <div className="otp-next-button-wrapper">
                <Button { ...nextButtonProps } >{texts.continue}</Button>
            </div>

            <Button { ...prevButtonProps } >{texts.back}</Button>

            <div className="otp-sms-ledge" />
        </div>
    )
}