import buildClassName from "../../helpers/buildClassName";
import './Button.css'
import _ from "../../helpers/_";
import {ReactComponent as LoadingButton} from "../../images/loading-button.svg";
import React from "react";

const classNamesType = {
    success: 'cbm-button-success',
    outline: 'cbm-button-outline'
}

export default props => {
    const {children = '', active = true, loading = false, type = 'success', fullWidth = false, onClick = () => ({})} = props

    let className = ['cbm-button', classNamesType[type]]

    if (loading || active === false) {
        className.push('cbm-button--disabled');
    }
    if (fullWidth) {
        className.push('cbm-button-full-width');
    }


    return <button className={buildClassName(className)} onClick={onClick}>
        {children}
        {_(loading, <LoadingButton />)}
    </button>
}
