import React from "react";
import productScreenRu from "../../images/product-screen-ru.png";
import productScreenUk from "../../images/product-screen-uk.png";
import productScreenEn from "../../images/product-screen-en.png";
import getLanguage from "../../helpers/getLanguage";
import {initialScreenWrapper as translations} from "../../translations";
import SelectLanguage from "../SelectLanguage/SelectLanguage";

const productScreenSrc = {
    ru: productScreenRu,
    uk: productScreenUk,
    en: productScreenEn
}

export default () => {
    const language = getLanguage()
    const texts = translations[language]

    return (
        <div className="initial-screen-decor-wrapper">
            <SelectLanguage/>
            <img src={productScreenSrc[language]} alt="Product Screen" />
            <div className="initial-screen-decor-title">{texts.digitalAssistant}</div>
            <div className="initial-screen-decor-text">{texts.decorText}</div>
        </div>
    )
}