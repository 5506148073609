import {
    ADD_PATIENT_DECREASE_WEEKS,
    ADD_PATIENT_HIDE_COMPONENT, ADD_PATIENT_INCREASE_WEEKS, ADD_PATIENT_INITIAL_STATE,
    ADD_PATIENT_NEXT_STEP, ADD_PATIENT_ONE_MORE, ADD_PATIENT_PREV_STEP,
    ADD_PATIENT_SHOW_COMPONENT, ADD_PATIENT_UPDATE_FIELD,
    LOGOUT,
    RESTORE_STORE
} from "./types";

const initialState = {
    showComponent: false,
    currentStep: 0,
    phone_number: '',
    messenger: '',
    type_treatment: '',
    diagnosis: '',
    nonWeightWeeks: 2,
    partialWeightWeeks: 2,
    increaseWeightWeeks: 2,
    partialWeightLoad: 30,
    increaseWeightLoad: 80,
    changeLoadComponentName: '',
    dataSent: false,
}

export const addPatientReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PATIENT_ONE_MORE: {
            return { ...initialState,
                showComponent: true
            }
        }
        case ADD_PATIENT_INCREASE_WEEKS: {
            const key = action.payload.key

            return { ...state,
                [key]: state[key] + 1
            }
        }
        case ADD_PATIENT_DECREASE_WEEKS: {
            const key = action.payload.key

            return { ...state,
                [key]: state[key] - 1
            }
        }
        case ADD_PATIENT_UPDATE_FIELD: {
            const key = action.payload.key
            const value = action.payload.value

            return { ...state,
                [key]: value
            }
        }
        case ADD_PATIENT_PREV_STEP: {
            return { ...state,
                currentStep: state.currentStep - 1
            }
        }
        case ADD_PATIENT_NEXT_STEP: {
            return { ...state,
                currentStep: state.currentStep + 1
            }
        }
        case ADD_PATIENT_HIDE_COMPONENT: {
            return { ...state,
                showComponent: false
            }
        }
        case ADD_PATIENT_SHOW_COMPONENT: {
            return { ...state,
                showComponent: true
            }
        }
        case RESTORE_STORE:
            return action.payload.addPatient
        case LOGOUT:
        case ADD_PATIENT_INITIAL_STATE:
            return initialState
        default: return state
    }
}