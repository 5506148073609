export const RESTORE_STORE = 'RESTORE_STORE'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const GET_PATIENTS = 'GET_PATIENTS'
export const UPDATE_PATIENT_DATA = 'UPDATE_PATIENT_DATA'
export const FILTERED_PATIENTS = 'FILTERED_PATIENTS'
export const PATIENTS_SEARCH_VALUE = 'PATIENTS_SEARCH_VALUE'
export const FILTERED_BY_SEARCH_PATIENTS = 'FILTERED_BY_SEARCH_PATIENTS'
export const DELETE_PATIENT = 'DELETE_PATIENT'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH'
export const TOGGLE_MENU_STATUS = 'TOGGLE_MENU_STATUS'
export const SET_SETTING_TAB_DATA = 'SET_SETTING_TAB_DATA'
export const SET_SETTING_DATA = 'SET_SETTING_DATA'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const GET_CITES = 'GET_CITES'
export const GET_HOSPITALS = 'GET_HOSPITALS'
export const UPDATE_DOCTOR = 'UPDATE_DOCTOR'
export const SHOW_FIELDS_ERRORS = 'SHOW_FIELDS_ERRORS'
export const UPDATE_FIELD_VALIDATION = 'UPDATE_FIELD_VALIDATION'
export const UNREGISTER_FIELD_VALIDATION = 'UNREGISTER_FIELD_VALIDATION'
export const DELETE_WORKPLACE = 'DELETE_WORKPLACE'
export const CREATION_ACCOUNT_DELETE_WORKPLACE = 'CREATION_ACCOUNT_DELETE_WORKPLACE'
export const ADD_WORKPLACE = 'ADD_WORKPLACE'
export const CREATION_ACCOUNT_ADD_WORKPLACE = 'CREATION_ACCOUNT_ADD_WORKPLACE'
export const UPDATE_JOBS_FIELD_VALUE = 'UPDATE_JOBS_FIELD_VALUE'
export const UPDATE_CREATION_ACCOUNT_JOBS_FIELD_VALUE = 'UPDATE_CREATION_ACCOUNT_JOBS_FIELD_VALUE'
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO'
export const REMOVE_PHOTO = 'REMOVE_PHOTO'
export const UPDATE_PHOTO_ERROR = 'UPDATE_PHOTO_ERROR'
export const UPDATE_CONTACTS = 'UPDATE_CONTACTS'
export const SET_POPUP = 'SET_POPUP'
export const SET_CONTENT_POPUP = 'SET_CONTENT_POPUP'
export const UPDATE_FIELD = 'UPDATE_FIELD'
export const UPDATE_SETTINGS_FIELD = 'UPDATE_SETTINGS_FIELD'
export const SET_SCROLL_OFFSET = 'SET_SCROLL_OFFSET'
export const GET_CITES_GOOGLE = 'GET_CITES_GOOGLE'
export const GET_HOSPITALS_GOOGLE = 'GET_HOSPITALS_GOOGLE'
export const GET_CONSULTATIONS = 'GET_CONSULTATIONS'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const UPDATE_DOCTOR_DATA = 'UPDATE_DOCTOR_DATA'
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT'
export const REQUEST_START = 'REQUEST_START'
export const REQUEST_END = 'REQUEST_END'
export const UPDATE_MY_PATIENTS_FIELD = 'UPDATE_MY_PATIENTS_FIELD'
export const UPDATE_PATIENT_FIELD = 'UPDATE_PATIENT_FIELD'
export const SET_INITIAL_PATIENT_STATE = 'SET_INITIAL_PATIENT_STATE'
export const CHANGE_CONTROL_TYPE = 'CHANGE_CONTROL_TYPE'
export const UPDATE_STEP_INDEX = 'UPDATE_STEP_INDEX'
export const CLOSE_POPUP = 'CLOSE_POPUP'
export const CHANGE_CURRENT_LOAD = 'CHANGE_CURRENT_LOAD'
export const UPDATE_CREATION_ACCOUNT_FIELD = 'UPDATE_CREATION_ACCOUNT_FIELD'
export const CREATION_ACCOUNT_SUBMIT = 'CREATION_ACCOUNT_SUBMIT'
export const GET_DAYS_DATA = 'PATIENT/GET_DAYS_DATA'
export const PUSH_DAY_DATA = 'PATIENT/PUSH_DAY_DATA'
export const ADD_PATIENT_SHOW_COMPONENT = 'ADD_PATIENT/SHOW_COMPONENT'
export const ADD_PATIENT_HIDE_COMPONENT = 'ADD_PATIENT/HIDE_COMPONENT'
export const ADD_PATIENT_NEXT_STEP = 'ADD_PATIENT/NEXT_STEP'
export const ADD_PATIENT_PREV_STEP = 'ADD_PATIENT/PREV_STEP'
export const ADD_PATIENT_UPDATE_FIELD = 'ADD_PATIENT/UPDATE_FIELD'
export const ADD_PATIENT_INCREASE_WEEKS = 'ADD_PATIENT/INCREASE_WEEKS'
export const ADD_PATIENT_DECREASE_WEEKS = 'ADD_PATIENT/DECREASE_WEEKS'
export const ADD_PATIENT_INITIAL_STATE = 'ADD_PATIENT/INITIAL_STATE'
export const ADD_PATIENT_ONE_MORE = 'ADD_PATIENT/ONE_MORE'
