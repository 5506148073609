import {
    CHANGE_CONTROL_TYPE, CLOSE_POPUP, GET_DAYS_DATA, PUSH_DAY_DATA,
    RESTORE_STORE,
    SET_INITIAL_PATIENT_STATE, UPDATE_PATIENT_DATA,
    UPDATE_PATIENT_FIELD,
    UPDATE_STEP_INDEX
} from "./types";

const initialState = {
    cardExpander: false,

    //patientData: [],
    patientData: {},
    patientStages: [],

    daysData: [],
    weeksData: [],
    monthsData: [],
    allData: [],

    activeControlType: 'all',
    activeStepIndex: 0,
    weekActiveStep: 0,
    monthActiveStep: 0,

    daysControlTexts: [{dateText: new Intl.DateTimeFormat('ru').format(new Date())}],
    weeksControlTexts: [],
    monthsControlTexts: [],
    allControlTexts: [],

    loaded: false,
    daysLoaded: false,
    dataPrepared: false,

    popup: '',
}

export const patientReducer = (state = initialState, action = {}) => {
    const payload = action.payload
    switch (action.type) {
        case UPDATE_PATIENT_DATA: {
            const patientData = payload.data

            return { ...state, patientData }
        }
        case PUSH_DAY_DATA: {
            const daysData = [ ...state.daysData]
            daysData.push(payload)

            return { ...state,
                daysData
            }
        }
        case GET_DAYS_DATA: {
            const daysData = action.payload.data

            return { ...state,
                daysData,
                daysLoaded: true,
            }
        }
        case CLOSE_POPUP: {
            return { ...state,
                popup: ''
            }
        }
        case UPDATE_STEP_INDEX: {
            const index = action.payload.index
            return { ...state,
                activeStepIndex: index
            }
        }
        case CHANGE_CONTROL_TYPE: {
            const value = action.payload.value
            return { ...state,
                activeControlType: value,
                activeStepIndex: 0,
            }
        }
        case SET_INITIAL_PATIENT_STATE: {
            return { ...initialState,
                cardExpander: state.cardExpander
            }
        }
        case UPDATE_PATIENT_FIELD: {
            const key = action.payload.key
            const value = action.payload.value
            return { ...state,
                [key]: value,
            }
        }
        // case RESTORE_STORE:
        //     return action.payload.patient
        default: return state
    }
}

