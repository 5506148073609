import { Chart, Bar} from "react-chartjs-2";
import React, {useEffect} from "react";
import './Chart.css'
import {useSelector} from "react-redux";
import {chart as translations} from "../../translations";
import dateForView from "../../helpers/dateForView";

Chart.elements.Rectangle.prototype.draw = function() {
    var ctx = this._chart.ctx;
    var vm = this._view;
    var left, right, top, bottom, signX, signY, borderSkipped, radius;
    var borderWidth = vm.borderWidth;

    // If radius is less than 0 or is large enough to cause drawing errors a max
    //      radius is imposed. If cornerRadius is not defined set it to 0.
    var cornerRadius = this._chart.config.options.cornerRadius;
    var fullCornerRadius = this._chart.config.options.fullCornerRadius;
    var stackedRounded = this._chart.config.options.stackedRounded;
    var typeOfChart = this._chart.config.type;

    if (cornerRadius < 0) {
        cornerRadius = 0;
    }
    if (typeof cornerRadius == 'undefined') {
        cornerRadius = 0;
    }
    if (typeof fullCornerRadius == 'undefined') {
        fullCornerRadius = false;
    }
    if (typeof stackedRounded == 'undefined') {
        stackedRounded = false;
    }

    if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
    } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        var halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
        }
    }

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth;

    // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |
    var corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom]
    ];

    // Find first (starting) corner with fallback to 'bottom'
    var borders = ['bottom', 'left', 'top', 'right'];
    var startCorner = borders.indexOf(borderSkipped, 0);
    if (startCorner === -1) {
        startCorner = 0;
    }

    function cornerAt(index) {
        return corners[(startCorner + index) % 4];
    }

    // Draw rectangle from 'startCorner'
    var corner = cornerAt(0);
    ctx.moveTo(corner[0], corner[1]);


    var nextCornerId, nextCorner, width, height, x, y;
    for (var i = 1; i < 4; i++) {
        corner = cornerAt(i);
        nextCornerId = i + 1;
        if (nextCornerId == 4) {
            nextCornerId = 0
        }

        nextCorner = cornerAt(nextCornerId);

        width = corners[2][0] - corners[1][0];
        height = corners[0][1] - corners[1][1];
        x = corners[1][0];
        y = corners[1][1];

        var radius = cornerRadius;
        // Fix radius being too large
        if (radius > Math.abs(height) / 2) {
            radius = Math.floor(Math.abs(height) / 2);
        }
        if (radius > Math.abs(width) / 2) {
            radius = Math.floor(Math.abs(width) / 2);
        }

        var x_tl, x_tr, y_tl, y_tr, x_bl, x_br, y_bl, y_br;
        if (height < 0) {
            // Negative values in a standard bar chart
            x_tl = x;
            x_tr = x + width;
            y_tl = y + height;
            y_tr = y + height;

            x_bl = x;
            x_br = x + width;
            y_bl = y;
            y_br = y;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);

            ctx.lineTo(x_br - radius, y_br);

            // bottom right
            ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);


            ctx.lineTo(x_tr, y_tr + radius);

            // top right
            fullCornerRadius ? ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr) : ctx.lineTo(x_tr, y_tr, x_tr - radius, y_tr);


            ctx.lineTo(x_tl + radius, y_tl);

            // top left
            fullCornerRadius ? ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius) : ctx.lineTo(x_tl, y_tl, x_tl, y_tl + radius);


            ctx.lineTo(x_bl, y_bl - radius);

            //  bottom left
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);

        } else if (width < 0) {
            // Negative values in a horizontal bar chart
            x_tl = x + width;
            x_tr = x;
            y_tl = y;
            y_tr = y;

            x_bl = x + width;
            x_br = x;
            y_bl = y + height;
            y_br = y + height;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);

            ctx.lineTo(x_br - radius, y_br);

            //  Bottom right corner
            fullCornerRadius ? ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius) : ctx.lineTo(x_br, y_br, x_br, y_br - radius);

            ctx.lineTo(x_tr, y_tr + radius);

            // top right Corner
            fullCornerRadius ? ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr) : ctx.lineTo(x_tr, y_tr, x_tr - radius, y_tr);

            ctx.lineTo(x_tl + radius, y_tl);

            // top left corner
            ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);

            ctx.lineTo(x_bl, y_bl - radius);

            //  bttom left corner
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);

        } else {

            var lastVisible = 0;
            for (var findLast = 0, findLastTo = this._chart.data.datasets.length; findLast < findLastTo; findLast++) {
                if (!this._chart.getDatasetMeta(findLast).hidden) {
                    lastVisible = findLast;
                }
            }
            var rounded = this._datasetIndex === lastVisible;

            if (rounded) {
                //Positive Value
                ctx.moveTo(x + radius, y);

                ctx.lineTo(x + width - radius, y);

                // top right
                ctx.quadraticCurveTo(x + width, y, x + width, y + radius);


                ctx.lineTo(x + width, y + height - radius);

                // bottom right
                if (fullCornerRadius || typeOfChart == 'horizontalBar')
                    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
                else
                    ctx.lineTo(x + width, y + height, x + width - radius, y + height);


                ctx.lineTo(x + radius, y + height);

                // bottom left
                if (fullCornerRadius)
                    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
                else
                    ctx.lineTo(x, y + height, x, y + height - radius);


                ctx.lineTo(x, y + radius);

                // top left
                if (fullCornerRadius || typeOfChart == 'bar')
                    ctx.quadraticCurveTo(x, y, x + radius, y);
                else
                    ctx.lineTo(x, y, x + radius, y);
            }else {
                ctx.moveTo(x, y);
                ctx.lineTo(x + width, y);
                ctx.lineTo(x + width, y + height);
                ctx.lineTo(x, y + height);
                ctx.lineTo(x, y);
            }
        }

    }

    ctx.fill();
    if (borderWidth) {
        ctx.stroke();
    }
};

const maxBarThickness = {
    days: 6,
    weeks: 60,
    months: 24,
    all: 6,
}
const cornerRadius = {
    days: 1,
    weeks: 8,
    months: 8,
    all: 1,
}
const labelsCallback = {
    days: (lang, step, i) => i,
    weeks: (lang, day) => {
        const date = new Date(day.date)
        return lang === 'en'
            ? dateForView(date, 'en-US')
            : new Intl.DateTimeFormat('ru').format(date)
    },
    months: (lang, day) => new Date(day.date).getDate(),
    all: (lang, day) => {
        const date = new Date(day.date)
        return lang === 'en'
            ? dateForView(date, 'en-US')
            : new Intl.DateTimeFormat('ru').format(date)
    }
}

let prevActiveControlType = ''
let prevActiveStepIndex = 0
let drawn = true
let initialLabelItemsPosition = []
let initialLabelItemsWidth = []

export default props => {
    const language = useSelector(state => state.app.lang)
    const status = useSelector(state => state.patient.patientData.status)
    const patientId = useSelector(state => state.patient.patientData.patient_id)
    const texts = translations[language]
    const payload = props.data
    const activeControlType = props.activeControlType
    const activeStepIndex = props.activeStepIndex
    const isAwaitingStatus = status === 'payment' || status === 'awaiting_start'

    if (activeControlType !== prevActiveControlType || activeStepIndex !== prevActiveStepIndex) {
        prevActiveControlType = activeControlType
        prevActiveStepIndex = activeStepIndex
        drawn = false
    }
    useEffect(() => () => (drawn = true) && (prevActiveControlType = '') && (prevActiveStepIndex = 0), [])

    const isAllData = activeControlType === 'all'
    let labelsForAllData = new Set()
    if (isAllData) {
        payload.filter(day => day.status !== 'empty').forEach(day => {
            const date = new Date(day.date)
            let month = date.toLocaleString(language, { month: 'long' }).split('.')[0]
            month = month.charAt(0).toUpperCase() + month.slice(1)
            labelsForAllData.add(month)
        })

    }

    //const labels = isAllData ? [...labelsForAllData] : payload.map(labelsCallback[activeControlType])
    const labels = isAwaitingStatus ? [] : payload.map(labelsCallback[activeControlType].bind(null, language))
    const lowLoadSteps = payload.map(day => day.statistics.low_load_steps_count)
    const normalLoadSteps = payload.map(day => day.statistics.normal_steps_count)
    const highLoadSteps = payload.map(day => day.statistics.high_load_steps_count)

    const fontColor = payload.map(day => day.status === 'empty' ? '#C9C9C9' : '#181827')

    const maxSteps = Math.max(...payload.map(day => Object.values(day.statistics).reduce((acc, val) => acc + +val, 0)))
    let max = maxSteps * 1.1 || 100
    const rounder = max < 999 ? 10 : 100
    const stepSize = Math.ceil(max / 5 / rounder) * rounder
    max = stepSize * 5

    let monthsLabel = []
    const xAxes = activeControlType === 'all'
        ? {
            stacked: true,
            //id:'xAxis2',
            gridLines: {
                drawOnChartArea: false,
                //display: false,
                drawBorder: false,
                offsetGridLines: true,
                tickMarkLength: 34,
                zeroLineWidth: 0,
                //borderDashOffset: 5.5,
                //z: 1,
                //color: '#F0F0F0',
                zeroLineColor: '#F0F0F0',
            },
            //type:"category",
            ticks:{
                // stepSize: 10,
                // display: true,
                //precision: 0,
                autoSkip: false,
                //sampleSize: 3,
                maxRotation: 0,
                padding: -21,
                fontFamily: 'Manrope',
                fontSize: 13,
                lineHeight: '22px',
                fontStyle: 500,
                labelOffset: 15,
                fontColor: '#181827',
                //z: 0,
                callback: function (label, i, labels) {
                    // получили месяц
                    const separator = language === 'en' ? '/' : '.'
                    const monthIndex = language === 'en' ? 0 : 1
                    const month = label.split(separator)[monthIndex]

                    if (!monthsLabel.includes(month)) {
                        // сохраняем его, если такого ещё нет в monthsLabel
                        monthsLabel.push(month)

                        // проверяем количество данных для месяца, если меньше 3 - ничего не возвращаем, иначе названия месяцев начнут накладываться на графике
                        const dataLength = labels.filter(label => label.split(separator)[monthIndex] === month).length
                        if (dataLength > 3) {
                            const date = new Date(label.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$2.$1.$3'))

                            // получаем текстовое представление месяца
                            let monthName = date.toLocaleString(language, { month: 'long' }).split('.')[0]
                            monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1)

                            // супер крутой костыль, т.к. в либе нет нормальной настройки для отступа
                            const labelOffset = i === 0 ? '' : [ ...monthName].map(() => ' ').join('')

                            return labelOffset + monthName
                        }
                    }
                }
            }
        }
        : {
            stacked: true,
            ticks : {
                padding: 12,
                fontFamily: 'Manrope',
                fontSize: 13,
                lineHeight: '22px',
                fontStyle: 500,
                fontColor,
            },
            gridLines: {
                display: false,
            },
        }

    const options = {
        maintainAspectRatio: false,
        responsive: false,
        cornerRadius: cornerRadius[activeControlType], // border-radius столбца
        legend: {
            display: false,
        },
        tooltips: {
            mode: 'index',
        },
        scales: {
            xAxes: [xAxes],
            yAxes: [{
                stacked: true,
                ticks : {
                    padding: 16,
                    fontFamily: 'Manrope',
                    fontSize: 13,
                    lineHeight: '22px',
                    fontColor: '#181827',
                    fontStyle: 500,
                    min: 0,
                    max,
                    stepSize
                },
                gridLines: {
                    color: '#F0F0F0',
                    drawTicks: false,
                    zeroLineColor: '#F0F0F0',
                },
            }]
        }
    }
    const greenDate = window.__greenDate || new Date(2022, 2, 30)
    const bgColors = {
        low: '#214C4F',
        normal: '#94B4A4',
        high: '#C25E6B',
    }
    const getBgColors = (status) => {
        if (patientId == 3182 || patientId == window.__patientId) {
            return payload.map((data) => data.date >= greenDate ? bgColors.normal : bgColors[status])
        }
        return bgColors[status]
    }
    const data = {
        labels,
        datasets: [{
            label: texts.insufficientLoad,
            backgroundColor: getBgColors('low'),
            data: lowLoadSteps,
            maxBarThickness: maxBarThickness[activeControlType], // ширина столбца
            status: 'low',
        }, {
            label: texts.recommendedLoad,
            backgroundColor: getBgColors('normal'),
            data: normalLoadSteps,
            maxBarThickness: maxBarThickness[activeControlType],  // ширина столбца
            status: 'normal',
        }, {
            label: texts.excessiveLoad,
            backgroundColor: getBgColors('high'),
            data: highLoadSteps,
            maxBarThickness: maxBarThickness[activeControlType],  // ширина столбца
            status: 'high',
        },]
    }

    const plugins = [{
        /*
        * border-right for bar chart
        * */
        id: 'custom_chart_area_border',
        beforeDraw: function (chart) {
            if (chart.config.type === 'bar') {
                var ctx = chart.chart.ctx;
                ctx.save();

                ctx.strokeStyle = chart.config.options.scales.xAxes[0].gridLines.color || Chart.defaults.bar.scales.xAxes[0].gridLines.color || "rgba(220,220,220,0.8)";
                ctx.lineWidth = chart.config.options.scales.xAxes[0].gridLines.lineWidth || Chart.defaults.bar.scales.xAxes[0].gridLines.lineWidth || 2;

                ctx.beginPath();

                ctx.moveTo(chart.chartArea.right, chart.chartArea.top);
                ctx.lineTo(chart.chartArea.right, chart.chartArea.bottom);
                ctx.stroke();

                ctx.restore();
            }
        }
    }, {
        /*
        * custom ticks color
        * */
        id: 'custom_ticks_color',
        afterDraw: function (chart) {
            if (activeControlType === 'all') {
                /*
                * Убираем полосу в конце лейблов под чартом
                * */
                var ctx = chart.scales['x-axis-0'].ctx;
                ctx.save();

                ctx.strokeStyle = 'white';
                ctx.lineWidth = 2;

                ctx.beginPath();

                ctx.moveTo(chart.scales['x-axis-0'].right, chart.scales['x-axis-0'].top);
                ctx.lineTo(chart.scales['x-axis-0'].right, chart.scales['x-axis-0'].bottom);
                ctx.stroke();
            } else {
                /*
                * Меняем цвет для лейблов без данных
                * */
                var ctx = chart.scales['x-axis-0'].ctx;
                let labelItems = []
                if (!drawn) {
                    labelItems = chart.scales['x-axis-0']._labelItems
                    initialLabelItemsPosition = labelItems
                    initialLabelItemsWidth = labelItems.map(item => ctx.measureText(item.label).width)
                    drawn = true
                }
                labelItems = initialLabelItemsPosition
                //labelItems = chart.scales['x-axis-0']._labelItems
                ctx.save()
                labelItems.forEach((item, i) => {
                    const width = initialLabelItemsWidth[i]
                    ctx.fillStyle = 'white';
                    //let { width } = ctx.measureText(item.label)
                    //width = +width.toFixed(0)
                    //if (i === 0) debugger
                    //ctx.fillRect(61.07833333333333 - 1, 204.2, width + 1, 22);
                    ctx.fillRect(item.x - width / 2, item.y, width, 22);
                    ctx.font = "500 13px/22px Manrope, sans-serif";
                    ctx.fillStyle = item.font.color[i]
                    //ctx.fillStyle = 'red'
                    ctx.fillText(item.label, item.x - width / 2, item.y + 13 + 2)
                    //ctx.fillText(item.label, item.x, item.y + 13 + 2)
                })
                ctx.restore()
            }
        },
        //afterDatasetUpdate: () => console.log('afterDatasetUpdate'),
        //afterRender: () => console.log('afterRender'),
        //afterDatasetDraw: () => console.log('afterDatasetDraw')
    }, /*{
        beforeUpdate: function(chart) {
            var backgroundColor = [];
            var borderColor = [];

            // For every data we have ...
            for (var i = 0; i < chart.config.data.datasets[0].data.length; i++) {

                // We generate a random color
                var color = "rgba(" + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 255) + ",";

                // We push this new color to both background and border color arrays
                // .. a lighter color is used for the background
                backgroundColor.push(color + "0.2)");
                borderColor.push(color + "1)");
            }

            // We update the chart bars color properties
            chart.config.data.datasets[0].backgroundColor = backgroundColor;
            chart.config.data.datasets[0].borderColor = borderColor;
        }
    }*/]

    return (
        <div className={`chart-wrapper`}>
            <div className="chart-small-canvas-wrapper" >
                <Bar width={1044} height={235} data={data} options={options} plugins={plugins} key={activeControlType + activeStepIndex}   />
            </div>
        </div>
    )
}