import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {hideNotification} from "../../store/actions"
import './Notification.css'
import {ReactComponent as CloseIcon} from "../../images/patients-filter-close-icon.svg"


export default () => {
    const dispatch = useDispatch()
    const notification = useSelector(state => state.app.notification)
    const targetText = notification.targetText
    const actionText = notification.actionText
    const type = notification.type
    const hideActionHandler = () => dispatch(hideNotification())

    useEffect(() => setTimeout(hideActionHandler, 3000), [])

    return (
        <div className={`notification ${type === 'error' ? 'notification-error' : ''}`}>
            <div>
                <span className="notification-target-text">{targetText}</span>
                <span className="notification-target-action">{actionText}</span>
            </div>
            <CloseIcon onClick={hideActionHandler}/>
        </div>
    )
}