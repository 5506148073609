import {combineReducers} from "redux"
import {appReducer} from "./appReducer"
import {myPatientsReducer} from "./myPatientsReducer"
import {settingsReducer} from "./settingsReducer"
import {patientReducer} from "./patientReducer"
import {creationAccountReducer} from "./creationAccountReducer"
import {validationReducer} from "./validationReducer";
import {popupReducer} from "./popupReducer";
import {addPatientReducer} from "./addPatientReducer";

export const rootReducer = combineReducers({
    app: appReducer,
    myPatients: myPatientsReducer,
    settings: settingsReducer,
    patient: patientReducer,
    creationAccount: creationAccountReducer,
    validation: validationReducer,
    popup: popupReducer,
    addPatient: addPatientReducer,
})