import React from "react";
import ChartSmall from "../ChartSmall/ChartSmall";
import SmartCircle from "../SmartCircle/SmartCircle";
import {ReactComponent as LoadIcon} from "../../images/load-icon.svg";
import {ReactComponent as EditIcon} from "../../images/edit-icon.svg";
import {useDispatch, useSelector} from "react-redux";
import './PatientRehabilitationProgram.css'
import {updatePatientField} from "../../store/actions";
import {patientRehabilitationProgram as translations} from "../../translations";
import _ from "../../helpers/_";


export default () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const patient = useSelector(state => state.patient.patientData)
    const patientStages = useSelector(state => state.patient.patientStages)
    const currentProgram = patient.current_program
    const texts = translations[language]

    if (!patientStages || !currentProgram) {
        return null
    }

    const isAwaitingStatus = patient.status === 'payment' || patient.status === 'awaiting_start'

    /*
    * Data
    * */
    const patientStagesLength = patientStages.length
    const maxPressure = patientStagesLength ? Math.max(...patientStages.map(stage => stage.end_pressure)) : null

    const patientCurrentLoad = currentProgram.current_load
        ? +(currentProgram.current_load).toFixed(0)
        : 0

    /* формируем firstDay, lastDay, data для каждого stage и сохраняем totalDays */
    let totalDays = 0
    patientStages.reduce((acc, stage) => {
        const firstDay = acc + 1
        const lastDay = acc + Number(stage.week_count) * 7
        const extraDeltasFull = stage.extra_deltas_full

        stage.data = extraDeltasFull
            ? Object.values(extraDeltasFull).map(val => +(val).toFixed())
            : [stage.start_pressure, stage.end_pressure]

        stage.firstDay = firstDay
        stage.lastDay = lastDay
        totalDays = lastDay
        return lastDay
    }, 0)

    /* данные для ползунка текущего дня и нагрузки */
    const patientCurrentDay = currentProgram.current_day
    const currentStage = patientStages.find(stage => stage.status === 'current')
    const currentPressureOffset = currentStage
        ? (patientCurrentDay - currentStage.firstDay) * 100 / (currentStage.lastDay - currentStage.firstDay)
        : null

    /*
    * Handlers
    * */
    const currentLoadClickHandler = () => dispatch(updatePatientField('popup', 'change_current_load'))

    /*
    * Components
    * */
    const dashComponent = className => <div className={`dash dash-${className}`} />
    const loadComponent = className => <div className={`chart-small-note chart-small-note-${className}`} >{texts.load}</div>
    const percentComponent = (percent = 0) => <div className={`chart-small-percent ${percent ? 'right' : ''}`} style={percent ? {bottom: percent + '%'} : null} >{percent}%</div>

    return (
        <>
            <div className="patient-rehabilitation-program-wrapper">
                <div className="patient-rehabilitation-program-title">{texts.rehabilitationProgram}</div>
                <div className="patient-rehabilitation-program-stages-wrapper">
                    {patientStages.map((stage, i) =>
                        <div className={`patient-rehabilitation-program-stage patient-rehabilitation-program-stage--${stage.status}`} key={'rehabilitationProgram' + i}>
                            <div className="patient-rehabilitation-program-stage-number">{i + 1}</div>
                            <div className="patient-rehabilitation-program-stage-status">{texts[stage.status + 'Status']}</div>
                            <div className="patient-rehabilitation-program-stage-load">
                                <span>{texts[stage.type]}</span>
                                <span className="patient-rehabilitation-program-stage-load-value">{stage.pressureText}</span>
                            </div>
                            <div className="patient-rehabilitation-program-weeks-wrapper">
                                {stage.weeksText}
                            </div>
                        </div>
                    )}

                </div>
            </div>
            <div className="patient-rehabilitation-details-wrapper">
            <div className="patient-rehabilitation-charts-wrapper">
                <div className="chart-small-items-wrapper">
                    {dashComponent('top-left')}
                    {dashComponent('top-right')}
                    {dashComponent('bottom-right')}
                    {percentComponent()}
                    {patientStages.map((stage, i) => {
                        const payload = {
                            data: stage.data,
                            status: stage.status,
                            weeks: stage.weeksText,
                            firstDay: stage.firstDay,
                            lastDay: stage.lastDay,
                            iteration: i,
                            totalDays,
                            patientStagesLength,
                            currentPressureOffset,
                            patientCurrentDay,
                            patientCurrentLoad,
                        }
                        return <ChartSmall payload={payload} key={'chartSmall' + patientStages.length + i} />
                    })}
                    {percentComponent(maxPressure)}
                    {loadComponent('bottom-left')}
                    {loadComponent('bottom-right')}
                </div>
            </div>
            <div className="patient-rehabilitation-current-load-wrapper">
                <SmartCircle className={`c100 p${patientCurrentLoad || 0} patient-rehabilitation-icon-wrapper`}>
                    <LoadIcon />
                </SmartCircle>
                <div className="patient-rehabilitation-current-load-inner-wrapper">
                    <div className="patient-rehabilitation-current-load">{patientCurrentLoad}%</div>
                    {_(!isAwaitingStatus,
                    <button className="patient-rehabilitation-current-load-button" onClick={currentLoadClickHandler}>
                        <EditIcon/>
                        <div className="patient-rehabilitation-current-load-text">{texts.changeCurrentLoad}</div>
                    </button>)}
                </div>
            </div>
        </div>
        </>
    )
}