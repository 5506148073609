import './SmartCircle.css'
import React from "react"

export default props => {
    return (
        <div className={props.className}>
            {props.children}
            <div className="slice">
                <div className="bar" />
                <div className="fill" />
            </div>
        </div>
    )
}