import { Doughnut } from "react-chartjs-2";
import React, {useState} from "react";
import {ReactComponent as SneakerIcon} from "../../images/sneaker-icon.svg";
import {useSelector} from "react-redux";
import {chartDoughnut as translations} from "../../translations";


const backgroundColor = {
    high: ['rgba(194, 94, 107, 0.2)', '#C25E6B', 'rgba(194, 94, 107, 0.2)'],
    normal: ['#94B4A4', '#EFF4F1', '#EFF4F1' ],
    low: ['rgba(33, 76, 79, 0.1)', 'rgba(33, 76, 79, 0.1)', '#214C4F']
}
const dataValue = {
    high: '',
    normal: '',
    low: ''
}

export default ({percents}) => {
    const language = useSelector(state => state.app.lang)
    const texts = translations[language]
    const [status, setStatus] = useState('normal')
    const datasetsData = percents
    dataValue.normal = (datasetsData[0]).toFixed(0)
    dataValue.high = (datasetsData[1]).toFixed(0)
    dataValue.low = (datasetsData[2]).toFixed(0)

    let segment;
    const options = {
        maintainAspectRatio: false,
        responsive: false,
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false,
        },
        layout: {
            padding: 2
        },
        cutoutPercentage: 35,
        onHover: function (evt, elements) {
            if (elements && elements.length) {
                segment = elements[0];
                this.chart.update();
                segment._model.outerRadius += 4;
                if (segment._model.label !== status) {
                    setStatus(segment._model.label)
                }
            } else {
                if (segment) {
                    segment._model.outerRadius -= 4;
                }
                segment = null;
            }
        }
    }
    const data = {
        datasets: [{
            data: datasetsData,
            //backgroundColor: ['#94B4A4', '#C25E6B', '#214C4F'],
            backgroundColor: backgroundColor[status],
            //borderColor: ['#94B4A4', '#C25E6B', '#214C4F'],
            borderColor: 'white',
            hoverBorderColor: 'white',
            //hoverBorderColor: ['#94B4A4', '#C25E6B', '#214C4F'],
            borderWidth: 3,
            //hoverBorderWidth: 6,
            //borderAlign: 'inner',
        }],

        labels: ['normal', 'high', 'low']
    }

    return (
        <div className="patient-chart-doughnut-wrapper">
            <div className={`patient-canvas-doughnut-wrapper patient-canvas-doughnut-wrapper--${status}`}>
                <Doughnut width={74} height={74} data={data} options={options} />
                <SneakerIcon />
            </div>
            <div className="patient-chart-doughnut-details">
                <div className={`patient-chart-doughnut-value patient-chart-doughnut-value--${status}`}>
                    {dataValue[status]}%
                </div>
                <div className={`patient-chart-doughnut-text patient-chart-doughnut-text--${status}`}>
                    {texts[status + 'Load']}
                </div>
            </div>
        </div>

    )
}