import './Popup.css'
import {useDispatch} from "react-redux";
import {setPopup} from "../../store/actions";

export default props => {
    const dispatch = useDispatch()
    const type = props.type
    const beforePopup = props.beforePopup || null
    const popupMainWrapperClassName = type === 'content'
        ? 'popup-content-wrapper'
        : 'popup-background'
    const closePopupHandler = () => dispatch(setPopup(''))

    return (
        <div className={popupMainWrapperClassName} onClick={closePopupHandler}>
            <div className="popup-wrapper" onClick={e => e.stopPropagation()}>
                {beforePopup}
                <div className={`popup ${props.className || ''}`}>
                    {props.children}
                </div>
                <div className="popup-ledge" />
            </div>
        </div>
    )
}