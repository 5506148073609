import {useSelector} from "react-redux"
import waveEffect from "../../helpers/waveEffect";
import './SupportPage.css'
import {ReactComponent as SupportIcon} from '../../images/support-icon.svg'
import supportPageImageSrc from '../../images/support-page.png'
import {ReactComponent as TelegramIcon} from '../../images/telegram-icon.svg'
import {ReactComponent as ViberIcon} from '../../images/viber-icon.svg'
import {ReactComponent as PhoneIcon} from '../../images/phone-icon.svg'
import {ReactComponent as WhatsApp} from '../../images/whatsapp-icon.svg'
import {ReactComponent as MailIcon} from '../../images/mail-icon.svg'
import {ReactComponent as MailIconWhite} from '../../images/mail-icon--white.svg'
import {supportPage as translations} from "../../translations";
import getFlow from "../../helpers/getFlow";
import _ from "../../helpers/_";
import getLanguage from "../../helpers/getLanguage";


export default () => {
    const language = useSelector(state => state.app.lang) || getLanguage()
    const country = useSelector(state => state.app.doctorData.country)

    const flow = getFlow(country || language)
    const isStandardFlow = flow === 'standard'
    const isUsFlow = flow === 'us'

    const textStandard = _(isStandardFlow,
        <div className="support-content-text">
            {translations[language].weAreOnline1} <br/>
            {translations[language].weAreOnline2}
        </div>)
    const group1Standard = _(isStandardFlow,
        <div className="group-wrapper">
            <div className="link-button-wrapper telegram-link-wrapper" onClick={waveEffect}>
                <a href="https://t.me/ComeBack_Mobility_bot" target="_blank">
                    <TelegramIcon/>
                    {translations[language].textMe}Telegram
                </a>
            </div>
            <div className="link-button-gap"/>
            <div className="link-button-wrapper viber-link-wrapper" onClick={waveEffect}>
                <a href="https://chats.viber.com/comebackmobility" target="_blank">
                    <ViberIcon/>
                    {translations[language].textMe}Viber
                </a>
            </div>
        </div>)
    const group1Us = _(isUsFlow,
        <div className="group-wrapper">
            <div className="link-button-wrapper whatsapp-link-wrapper" onClick={waveEffect}>
                <a href="https://wa.me/380989999880" target="_blank">
                    <WhatsApp/>
                    {translations[language].textMe}WhatsApp
                </a>
            </div>
            <div className="link-button-gap"/>
            <div className="link-button-wrapper email-link-wrapper" onClick={waveEffect}>
                <a href="mailto:info@comebackmobility.com">
                    <MailIconWhite />
                    {translations[language].textMe}Email
                </a>
            </div>
        </div>)
    const group2Standard = _(isStandardFlow,
        <>
            <div className="links-separator" />
            <div className="group-wrapper">
                <a href="tel:+380989999880" className="support-link">
                    <span className="phone-icon-wrapper">
                        <PhoneIcon/>
                    </span>
                    <span className="support-link-text">+38 098 999 98 80</span>
                </a>
                <div className="support-link-gap" />
                <a href="mailto:info@comebackmobility.com" className="support-link">
                    <span className="mail-icon-wrapper">
                        <MailIcon />
                    </span>
                    <span className="support-link-text">info@comebackmobility.com</span>
                </a>
            </div>
        </>)

    return (
        <>
            <header className="support-header">
                <div className="support-icon-wrapper">
                    <SupportIcon />
                </div>
                <div className="support-header-title">
                    {translations[language].support}
                </div>
            </header>
            <div className="support-content-wrapper">
                <img src={supportPageImageSrc} alt="Icon" />
                <div className="support-content-title">
                    {translations[language].needHelp}
                </div>
                {textStandard}
                <div className="links-wrapper">
                    {group1Standard}
                    {group1Us}
                    {group2Standard}
                </div>
            </div>
        </>
    )
}