import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import getLanguage from "../../helpers/getLanguage";
import {creationAccountAcquaintance as translations} from "../../translations";
import {
    logout,
    removePhoto, showFieldsErrors,
    updateCreationAccountField,
    updateFieldValidation, uploadPhoto,
} from "../../store/actions";
import ValidateField from "../ValidateField/ValidateField";
import './CreationAccountAcquaintance.css'
import {ReactComponent as BackIcon} from "../../images/arrow-back-big-icon.svg"
import _ from "../../helpers/_";


export default () => {
    const dispatch = useDispatch()
    const doctorData = useSelector(state => state.app.doctorData)
    const validation = useSelector(state => state.validation.validation)
    const lastName = useSelector(state => state.creationAccount.last_name)
    const firstName = useSelector(state => state.creationAccount.first_name)
    const photo = useSelector(state => state.creationAccount.photo)
    const otpUaFlow = useSelector(state => state.app.otpUaFlow)
    const isStepValid = Object.values(validation).every(item => item.valid === true)
    const language = getLanguage()
    const texts = translations[language]
    const isUsFlow = otpUaFlow === false

    useEffect(() => {
        doctorData.last_name && dispatch(updateCreationAccountField({key: 'last_name', value: doctorData.last_name}))
        doctorData.first_name && dispatch(updateCreationAccountField({key: 'first_name', value: doctorData.first_name}))
        doctorData.photo && dispatch(updateCreationAccountField({key: 'photo', value: doctorData.photo}))
    }, [doctorData.photo])

    /*
    * Prepare data
    * */
    const nextButtonClassName = isStepValid ? 'button-success' : 'button-disabled'

    /*
    * Handlers
    * */
    const fieldChangeHandler = key => ({target: {value}}) => dispatch(updateCreationAccountField({key, value}))
    const fieldBlurHandler = key => ({target: {value}}) => {
        dispatch(updateCreationAccountField({ key, value: value.trim() }))
        const enableError = validation.hasOwnProperty(key) && !validation[key].showError
        if (enableError) {
            dispatch(updateFieldValidation({id: key, showError: true}))
        }
    }
    const photoChangeHandler = e => {
        const formData = new FormData()
        const image = e.target.files[0]

        if (image.type.toLowerCase() === 'image/png' ||
            image.type.toLowerCase() === 'image/jpeg' ||
            image.type.toLowerCase() === 'image/jpg'
        ) {
            formData.append("image", image)
            dispatch(uploadPhoto('', '', formData))
        }
        e.target.value = ''
    }
    const removePhotoHandler = () => {
        dispatch(removePhoto('', '', { photo: '' }))
    }
    const prevButtonClickHandler = () => dispatch(logout())
    const nextButtonClickHandler = e => {
        if (isStepValid) {
            dispatch(updateCreationAccountField({ key: 'currentStep', value: 1 }))
        } else {
            dispatch(showFieldsErrors())
        }
    }

    /*
    * Validation
    * */
    const lastNameValidationRules = [[lastName.trim().length < 2 || lastName.trim().length > 50, texts.namesErrorText]]
    const lastNameShowError = (validation && validation.last_name && validation.last_name.showError) || false

    const firstNameValidationRules = [[firstName.trim().length < 2 || firstName.trim().length > 50, texts.namesErrorText]]
    const firstNameShowError = (validation && validation.first_name && validation.first_name.showError) || false

    return (
        <>
            {/*last name*/}
            <label className="tab-label">
                <div className="tab-label-text">
                    {texts.lastName}
                </div>
                <ValidateField id="last_name" validation={lastNameValidationRules} showError={lastNameShowError}>
                    <input value={lastName} placeholder={texts.lastNamePlaceholder} className="tab-input" type="text"
                           onChange={fieldChangeHandler('last_name')}
                           onBlur={fieldBlurHandler('last_name')} />
                </ValidateField>
            </label>

            {/*first name*/}
            <label className="tab-label">
                <div className="tab-label-text">
                    {texts.firstName}
                </div>
                <ValidateField id="first_name" validation={firstNameValidationRules} showError={firstNameShowError}>
                    <input value={firstName} placeholder={texts.firstNamePlaceholder} className="tab-input" type="text"
                           onChange={fieldChangeHandler('first_name')}
                           onBlur={fieldBlurHandler('first_name')} />
                </ValidateField>
            </label>

            {/*photo*/}
            {_(isUsFlow,
                <>
                    <div className="creation-account-separator" />

                    <div className="creation-account-section-title">{texts.addPhotoTitle}</div>
                    <div className="creation-account-section-text">{texts.addPhotoText}</div>

                    <div className="creation-account-photo-wrapper">
                        {photo
                            ? <label htmlFor="photo" className="photo-wrapper">
                                <img src={photo} alt="Profile Image" className="photo"/>
                            </label>
                            : <label htmlFor="photo" className="photo-label" />}

                        <input type="file" id="photo" accept=".jpg, .jpeg, .png" onChange={photoChangeHandler} />

                        {/*{_(photo, <button className="remove-photo" onClick={removePhotoHandler}><DeleteIcon /></button>)}*/}
                    </div>
                </>
            )}

            {/*controls*/}
            <div className="creation-account-controls-wrapper">
                <button className={`button button-outline-secondary`} onClick={prevButtonClickHandler}>
                    <BackIcon className="creation-account-back-icon" />{texts.back}
                </button>
                <button className={`button ${nextButtonClassName}`} onClick={nextButtonClickHandler}>
                    {texts.next}
                </button>
            </div>
        </>
    )
}