import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import './ChangePhoto.css'
import {removePhoto, updatePhotoError, uploadPhoto} from "../../store/actions";
import {ReactComponent as DeleteIcon} from '../../images/delete-icon.svg'
import {ReactComponent as ErrorIcon} from '../../images/error-icon.svg'
import {changePhoto as translations} from "../../translations";


export default () => {
    const tabKey = 'changePhoto'
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const token = useSelector(state => state.app.token)
    const doctorId = useSelector(state => state.app.doctorId)
    const photo = useSelector(state => state.app.doctorData.photo)
    const error = useSelector(state => state.settings.photoError)

    useEffect(() => error && dispatch(updatePhotoError('')), [])

    const photoChangeHandler = e => {
        const formData = new FormData()
        const image = e.target.files[0]
        formData.append("image", image);
        e.target.value = ''
        if (image.size > 5000000) {
            dispatch(updatePhotoError(translations[language].photoNotExceed))
        } else {
            dispatch(updatePhotoError(''))
            dispatch(uploadPhoto(token, doctorId, formData))
        }
    }
    const removePhotoHandler = () => {
        dispatch(updatePhotoError(''))
        dispatch(removePhoto(token, doctorId, { photo: '' }))
    }

    return (
        <div className="tab-section">
            <div className="photo-container">
                <div>
                    {photo ?
                        <label htmlFor="photo" className="photo-wrapper">
                            <img src={photo} alt="Profile Image" className="photo"/>
                        </label>

                        : <label htmlFor="photo" className="photo-label" />}

                    <input type="file" id="photo" accept=".jpg, .jpeg, .png" onChange={photoChangeHandler} />
                    {
                        photo
                            ? <button className="remove-photo" onClick={removePhotoHandler}><DeleteIcon /> {translations[language].removePhoto}</button>
                            : <div className="photo-text">{translations[language].uploadText}</div>
                    }
                </div>
                {error ? <div className="photo-error"><ErrorIcon />{error}</div> : null}
            </div>
        </div>
    )
}