import {
    GET_NOTIFICATIONS,
    GET_CONSULTATIONS,
    UPDATE_FIELD,
    UPDATE_CONTACTS,
    REMOVE_PHOTO,
    UPLOAD_PHOTO,
    UPDATE_DOCTOR,
    TOGGLE_MENU_STATUS,
    LOGIN,
    LOGOUT,
    RESTORE_STORE,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    SET_WINDOW_WIDTH,
    SET_SCROLL_OFFSET, UPDATE_DOCTOR_DATA, SET_CONTENT_POPUP, REQUEST_START, REQUEST_END
} from "./types"
import getLanguage from "../helpers/getLanguage";

const initialState = {
    email: '',
    password: '',
    lang: getLanguage(),
    token: '',
    doctorId: '',
    doctorData: {},
    isAuth: false,
    notification: {
        show: false,
        targetText: '',
        actionText: '',
    },
    windowWidth: null,
    menuOpened: false,
    validation: {},
    pageOffset: 0,
    consultations: {
        count: 0,
        data: [],
        loaded: false,
    },
    notifications: {
        count: 0,
        data: [],
        loaded: false,
    },
    contentPopup: '',
    requestInProgress: false,
    otpCountry: 'disabled',
    otpCountryValue: '',
    otpUaFlow: false,
    otpStageName: 'otp_enter_phone',
    otpPhoneNumber: '',
    otpIsResend : false,
    otpToken: '',
    otpValid: true,
}

export const appReducer = (state = initialState, action) => {
    let key, value, data
    switch (action.type) {
        case REQUEST_END:
            return { ...state, requestInProgress: false }
        case REQUEST_START:
            return { ...state, requestInProgress: true }
        case SET_CONTENT_POPUP:
            return { ...state,
                contentPopup: action.payload
            }
        case GET_NOTIFICATIONS: {
            data = action.payload
            if (data) {
                return { ...state,
                    notifications: { ...data,
                        loaded: true,
                    },
                }
            }
            return state
        }
        case GET_CONSULTATIONS: {
            data = action.payload
            if (data) {
                return { ...state,
                    consultations: { ...data,
                        loaded: true,
                    },
                }
            }
            return state
        }
        case SET_SCROLL_OFFSET: {
            return { ...state,
                pageOffset: action.payload
            }
        }
        case UPDATE_FIELD: {
            key = action.payload.key
            value = action.payload.value
            return { ...state,
                [key]: value,
            }
        }
        case UPDATE_CONTACTS:
            if (action.payload.data) {
                return { ...state,
                    doctorData: { ...state.doctorData,
                        messenger: action.payload.data.messenger,
                        phone: action.payload.data.messenger
                    }
                }
            }
            return state
        case REMOVE_PHOTO:
            if (action.payload.data && action.payload.data.photo !== undefined) {
                return { ...state,
                    doctorData: { ...state.doctorData,
                        photo: action.payload.data.photo,
                    }
                }
            }
            return state
        case UPLOAD_PHOTO:
            if (action.payload.data && action.payload.data.filename) {
                return { ...state,
                    doctorData: { ...state.doctorData,
                        photo: action.payload.data.filename,
                    }
                }
            }
            return state
        case RESTORE_STORE:
            return action.payload.app
        case UPDATE_DOCTOR_DATA:
            return { ...state,
                doctorData: action.payload.data,
                email: action.payload.data.email,
            }
        case LOGIN:
            return { ...state,
                token: action.payload.access_token,
                doctorId: action.payload.data.id,
                doctorData: action.payload.data,
                isAuth: true,
                email: action.payload.data.email,
            }
        case LOGOUT:
            clearInterval(window.cbm_notificationsInterval)
            return { ...initialState,
                windowWidth: state.windowWidth,
            }
        case SHOW_NOTIFICATION:
            if (action.payload) {
                return { ...state,
                    notification: {
                        show: true,
                        targetText: action.payload.targetText,
                        actionText: action.payload.actionText,
                        type: action.payload.type,
                    }
                }
            }
            return state
        case HIDE_NOTIFICATION:
            return { ...state,
                notification: {
                    show: false,
                    targetText: '',
                    actionText: '',
                    type: '',
                }
            }
        case SET_WINDOW_WIDTH:
            return { ...state,
                windowWidth: action.payload
            }
        case TOGGLE_MENU_STATUS:
            return { ...state,
                menuOpened: !state.menuOpened
            }
        case UPDATE_DOCTOR:
            if (action.payload.data) {
                return { ...state,
                    doctorData: action.payload.data,
                }
            }
            return state
        default: return state
    }
}