import ChartDoghnut from "../ChartDoughnut/ChartDoughnut";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Chart from "../Chart/Chart";
import {changeControlType, updateStepIndex} from "../../store/actions";
import './PatientSteps.css'
import {ReactComponent as ChevroneLeftIcon} from "../../images/chevrone-left-icon.svg";
import {ReactComponent as ChevroneRightIcon} from "../../images/chevrone-right-icon.svg";
import _ from "../../helpers/_";
import {patientSteps as translations} from "../../translations";
import ChartDay from "../ChartDay/ChartDay";


export default () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const status = useSelector(state => state.patient.patientData.status)
    const activeControlType = useSelector(state => state.patient.activeControlType)
    const activeStepIndex = useSelector(state => state.patient.activeStepIndex)
    const dataList = useSelector(state => state.patient[activeControlType + 'Data'])
    const data = activeControlType === 'all' || activeControlType === 'days' ? dataList : dataList[activeStepIndex]
    const controlText = useSelector(state => state.patient[activeControlType + 'ControlTexts'][activeStepIndex])
    const texts = translations[language]
    const isAwaitingStatus = status === 'payment' || status === 'awaiting_start'

    // if (!data || !data.length) {
    //     return null
    // }
    if (activeControlType === 'days') {
        controlText.text = texts.day
    }

    let lowLoadSum, normalLoadSum, highLoadSum, allLoadSum, lowLoadPercent, normalLoadPercent, highLoadPercent

    if (activeControlType === 'days') {
        lowLoadSum = data.filter(step => step.stepType === 'low').length
        normalLoadSum = data.filter(step => step.stepType === 'normal').length
        highLoadSum = data.filter(step => step.stepType === 'high').length
    } else {
        lowLoadSum = data.reduce((acc, day) => acc + day.statistics.low_load_steps_count, 0)
        normalLoadSum = data.reduce((acc, day) => acc + day.statistics.normal_steps_count, 0)
        highLoadSum = data.reduce((acc, day) => acc + day.statistics.high_load_steps_count, 0)
    }

    allLoadSum = lowLoadSum + normalLoadSum + highLoadSum
    lowLoadPercent = lowLoadSum * 100 / allLoadSum
    normalLoadPercent = normalLoadSum * 100 / allLoadSum
    highLoadPercent = highLoadSum * 100 / allLoadSum

    const getChartTypeControlClassName = value => value === activeControlType ? 'patient-chart-type-control patient-chart-type-control--active' : 'patient-chart-type-control'

    /*
    * Handlers
    * */
    const typeControlClickHandler = value => () => dispatch(changeControlType({value}))
    const stepIndexClickHandler = delta => () => dispatch(updateStepIndex({index: activeStepIndex + delta}))

    /*
    * Components
    * */
    const prevButton = activeStepIndex !== 0
        ? <button className="patient-chart-step-control patient-chart-step-control--prev" onClick={stepIndexClickHandler(-1)}><ChevroneLeftIcon /></button>
        : null
    const nextButton = activeStepIndex !== dataList.length - 1 && activeControlType !== 'all' && activeControlType !== 'days'
        ? <button className="patient-chart-step-control" onClick={stepIndexClickHandler(1)}><ChevroneRightIcon /></button>
        : null

    const controlTextComponentCondition = controlText && controlText.text && controlText.dateText
    const controlTextText = controlText ? controlText.text : ''
    const controlTextDateText = controlText ? controlText.dateText : ''

    const controlTextComponent = _(controlTextComponentCondition,
        <>
            <span className="patient-chart-step-control-text">{controlTextText}</span>
            &nbsp;
            <span className="patient-chart-step-control-date-text">{controlTextDateText}</span>
        </>)

    return (
        <div className="patient-chart-section-wrapper">
            <div className="patient-chart-title">{texts.patientChartTitle}</div>
            <div className="patient-chart-content-wrapper">

                {_(!isAwaitingStatus,
                <div className="patient-chart-controls-wrapper">
                    <div className="patient-chart-step-control-wrapper">
                        {prevButton}
                        {controlTextComponent}
                        {nextButton}
                    </div>
                    <div className="patient-chart-type-controls-wrapper">
                        <button className={getChartTypeControlClassName('all')} onClick={typeControlClickHandler('all')}>{texts.allPeriod}</button>
                        <button className={getChartTypeControlClassName('months')} onClick={typeControlClickHandler('months')}>{texts.month}</button>
                        <button className={getChartTypeControlClassName('weeks')} onClick={typeControlClickHandler('weeks')}>{texts.week}</button>
                        <button className={getChartTypeControlClassName('days')} onClick={typeControlClickHandler('days')}>{texts.day}</button>
                    </div>
                </div>)}

                <div className="patient-chart-axes-title y-axes">{activeControlType === 'days' ? texts.loadPercentage : texts.totalSteps}</div>

                {/*chart*/}
                {activeControlType === 'days'
                    ? <ChartDay data={data} activeControlType={activeControlType} activeStepIndex={activeStepIndex}  />
                    : <Chart data={data} activeControlType={activeControlType} activeStepIndex={activeStepIndex}  />
                }

                {/*chart-notice*/}
                <div className="patient-chart-notice">
                    <div className="patient-chart-axes-title x-axes">{texts.daysUsing}</div>
                    <div className="legend-labels-wrapper">
                        <div className="legend-label legend-label--high">{texts.excessiveLoad}</div>
                        <div className="legend-label legend-label--normal">{texts.recommendedLoad}</div>
                        <div className="legend-label legend-label--low">{texts.insufficientLoad}</div>
                    </div>
                </div>

                {/*chart-footer*/}
                {_(normalLoadPercent || highLoadPercent || lowLoadPercent,
                <div className="patient-chart-footer">
                    <ChartDoghnut percents={[normalLoadPercent, highLoadPercent, lowLoadPercent]} />
                </div>)}

            </div>
        </div>
    )
}