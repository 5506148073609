import {
    GET_HOSPITALS_GOOGLE,
    GET_CITES_GOOGLE,
    SET_CONTENT_POPUP,
    SET_POPUP,
    UPDATE_PHOTO_ERROR,
    GET_HOSPITALS,
    UPDATE_JOBS_FIELD_VALUE,
    ADD_WORKPLACE,
    DELETE_WORKPLACE,
    GET_CITES,
    SET_SETTING_DATA,
    SET_SETTING_TAB_DATA,
    RESTORE_STORE,
    SET_ACTIVE_TAB,
    LOGOUT,
    UPDATE_SETTINGS_FIELD
} from "./types"

const initialState = {
    activeTabId: null,
    temp: {
        changeCredentials: {
            specialization: '',
            specializationOther: '',
            experience: '',
            jobs: [],
            loaded: false,
        },
        changeLanguage: {
            language: 'ru',
        },
        changeContacts: {
            phone: '',
            messenger: '',
            loaded: false,
        },
    },
    cites: [],
    workplaces: [],
    photoError: '',
    popup: '',
    contentPopup: '',
}

export const settingsReducer = (state = initialState, action) => {
    let validation, key, i, jobs, value, id, valid, showError, search, data
    switch (action.type) {
        case SET_CONTENT_POPUP:
            return { ...state,
                contentPopup: action.payload
            }
        case SET_POPUP:
            return { ...state,
                popup: action.payload
            }
        case UPDATE_PHOTO_ERROR:
            return { ...state,
                photoError: action.payload
            }
        case UPDATE_SETTINGS_FIELD: {
            const key = action.payload.key
            const value = action.payload.value
            return { ...state, [key]: value }
        }
        case UPDATE_JOBS_FIELD_VALUE:
            i = action.payload.i
            key = action.payload.key
            value = action.payload.value
            jobs = [ ...state.temp.changeCredentials.jobs]
            jobs[i] = { ...jobs[i],
                [key]: value,
            }

            return { ...state,
                temp: { ...state.temp,
                    changeCredentials: { ...state.temp.changeCredentials,
                        jobs,
                    }
                },
            }
        case ADD_WORKPLACE:
            jobs = [...state.temp.changeCredentials.jobs, {
                id: action.payload.id,
                city: '',
                language: action.payload.language,
                institute: '',
            }]
            return { ...state,
                temp: { ...state.temp,
                    changeCredentials: { ...state.temp.changeCredentials,
                        jobs,
                    }
                },
            }
        case RESTORE_STORE:
            return action.payload.settings
        case LOGOUT: return initialState
        case SET_SETTING_TAB_DATA:
            key = action.payload.key
            data = action.payload.data ? { ...action.payload.data, loaded: true } : initialState.temp[key]

            return { ...state,
                temp: { ...state.temp,
                    [key]: data,
                },
            }
        case SET_SETTING_DATA:
            const tabKey = action.payload.tabKey
            const settingKey = action.payload.key
            value = action.payload.value

            return { ...state,
                temp: { ...state.temp,
                    [tabKey]: { ...state.temp[tabKey],
                        [settingKey]: value,
                    },
                },
            }
        case SET_ACTIVE_TAB:
            //const newActiveTabId = state.activeTabId === action.payload ? null : action.payload

            return { ...state,
                //activeTabId: newActiveTabId,
                activeTabId: action.payload,
            }
        case GET_CITES_GOOGLE: {
            if (state.activeTabId !== 'changeCredentials') {
                return state
            }
            const key = action.payload.key
            const i = key.replace(/\D/g, '')
            const search = action.payload.search
            const value = state.temp.changeCredentials.jobs[i].city.split(',')[0]
            const data = action.payload.data

            if ((search === value || value === '') && data) {
                return { ...state,
                    cites: { ...state.cites,
                        [key]: data,
                    }
                }
            }
            return state
        }
        case GET_CITES:
            if (action.payload.data) {
                return { ...state,
                    cites: action.payload.data,
                }
            }
            return state
        case GET_HOSPITALS:
            if (action.payload.data) {
                return { ...state,
                    hospitals: action.payload.data,
                }
            }
            return state
        case GET_HOSPITALS_GOOGLE: {
            if (state.activeTabId !== 'changeCredentials') {
                return state
            }

            const key = action.payload.key
            const i = key.replace(/\D/g, '')
            const search = action.payload.search
            const value = state.temp.changeCredentials.jobs[i].institute
            const data = action.payload.data

            if ((search === value || value === '') && data) {
                return { ...state,
                    workplaces: { ...state.workplaces,
                        [key]: data,
                    }
                }
            }
            return state
        }
        case DELETE_WORKPLACE:
            i = action.payload
            jobs = [ ...state.temp.changeCredentials.jobs]
            jobs.splice(i, 1)

            return { ...state,
                temp: { ...state.temp,
                    changeCredentials: { ...state.temp.changeCredentials,
                        jobs,
                    }
                },
            }
        default: return state
    }
}