import {ReactComponent as Logo} from "../../images/logo-medium.svg";
import './Loader.css'

export default () => {
    return (
        <div className="loader-wrapper">
            <div className="loader-progress"/>
            <div className="loader">
                <Logo />
            </div>
        </div>
    )
}