import {useSelector, useDispatch} from "react-redux"
import {logout} from "../../store/actions"
import './Profile.css'
import {ReactComponent as LogOutIcon} from '../../images/profile-log-out.svg'
import {ReactComponent as DoctorPhotoEmpty} from '../../images/doctor-photo-empty-icon.svg'
import {profile as translations} from "../../translations";


export default () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const token = useSelector(state => state.app.token)
    const { photo, first_name, last_name, specialization } = useSelector(state => state.app.doctorData)
    const doctorImage = photo ?
        <img src={photo} alt="Profile Image" className="profile-img"/> :
        <DoctorPhotoEmpty />
    const logoutHandler = () => dispatch(logout(token))


    return (
        <div className="profile-wrapper">
            <div className="profile">
                <div className="profile-img-wrapper">
                    {doctorImage}
                </div>
                <div className="profile-data">
                    <div className="profile-full-name">
                        {first_name + ' ' + last_name}
                    </div>
                    <div className="profile-specialization">
                        {specialization}
                    </div>
                </div>
            </div>
            <div className="profile-action-wrapper">
                <button id="profileLogOut" className="profile-action" onClick={logoutHandler}>
                    <LogOutIcon />
                    {translations[language].logOut}
                </button>
            </div>
        </div>
    )
}