import React from "react";
import Popup from "../Popup/Popup";
import emoji23Icon from "../../images/emoji-23-icon.png";
import {registrationSuccessful as translations} from "../../translations";
import {useSelector} from "react-redux";
import {ReactComponent as LogoIcon} from "../../images/logo-initial-screen-icon.svg";

export default () => {
    const language = useSelector(state => state.app.lang)
    const texts = translations[language]

    return (
        <div className="registration-popup-wrapper">
            <LogoIcon />
            <Popup type="content">
                <img src={emoji23Icon} alt="Icon" width="120" height="120" />
                <div className="popup-title">{texts.registrationSuccessful}</div>
            </Popup>
        </div>
    )
}