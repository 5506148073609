export default (country) => {
    switch (country) {
        case 'az':
        case 'am':
        case 'by':
        case 'kz':
        case 'kg':
        case 'md':
        case 'ru':
        case 'tj':
        case 'tm':
        case 'uz':
        case 'ua':
        case 'uk':
        case 'nonUS':
        case null:
        case '':
            return 'standard'
        default:
            return 'us'
    }
}