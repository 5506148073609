import {useSelector, useDispatch} from "react-redux"
import {toggleMenuStatus} from "../../store/actions"
import MainNav from "../MainNav/MainNav"
import Profile from "../Profile/Profile"
import './MainHeader.css'
import {ReactComponent as LogoImg} from '../../images/logo.svg'
import {ReactComponent as MobileLogoImg} from '../../images/mobile-logo.svg'
import {mainHeader as translations} from "../../translations";


export default () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const windowWidth = useSelector(state => state.app.windowWidth)
    const menuOpened = useSelector(state => state.app.menuOpened)
    const toggleMenuStatusHandler = () => dispatch(toggleMenuStatus())

    const desktopNav = windowWidth > 1300 ? <MainNav/> : null
    const desktopGroupWrapper = (
        windowWidth > 1300 ?
            <div className="group-wrapper">
                <div className="logo-wrapper">
                    <LogoImg/>
                </div>

                <div className="copyright">
                    &copy; {new Date().getFullYear()}. <br/>
                    {translations[language].copyright}.
                </div>
            </div> :
            null
    )
    const tabletMenuButton = (
        windowWidth <= 1300 ?
            <div className={`menu-button ${menuOpened ? 'menu-button--active' : ''}`} onClick={toggleMenuStatusHandler} />
            : null
    )
    const tabletLogo = windowWidth <= 1300 ? <MobileLogoImg/> : null
    const tabletMenu = windowWidth <= 1300 ? <MainNav/> : null


    return (
        <>
            <header className={`main-header ${menuOpened ? 'main-header--menu-opened' : ''}`}>
                <div className="group-wrapper">
                    {tabletMenuButton}
                    {tabletLogo}
                    <Profile/>
                    {desktopNav}
                </div>
                {desktopGroupWrapper}
                {tabletMenu}
            </header>
        </>
    )
}