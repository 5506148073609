import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import Tab from "../../components/Tab/Tab"
import ChangeCredentials from "../../components/ChangeCredentials/ChangeCredentials"
import ChangeLanguage from "../../components/ChangeLanguage/ChangeLanguage"
import './SettingsPage.css'
import {ReactComponent as SettingsIcon} from '../../images/settings-icon.svg'
import ChangePhoto from "../../components/ChangePhoto/ChangePhoto";
import DeleteAccount from "../../components/DeleteAccount/DeleteAccount";
import {setActiveTab, updateDoctorData} from "../../store/actions";
import {settingsPage as translations} from "../../translations";

export default () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    let contentPopupContainer = null

    useEffect(() => {
        dispatch(updateDoctorData)
        if (window.location.hash) {
            const id = window.location.hash.replace(/#/g, '')
            dispatch(setActiveTab(id))
        }
        return () => dispatch(setActiveTab(null))
    }, [])

    const mainContent = (
        <>
            <header className="settings-header">
                <div className="settings-icon-wrapper">
                    <SettingsIcon />
                </div>
                <div className="settings-header-title">
                    {translations[language].settings}
                </div>
            </header>
            <div className="settings-content-wrapper">
                <Tab id="changeCredentials" title={translations[language].changeCredentials}>
                    <ChangeCredentials />
                </Tab>
                {/*<Tab id="changeContactDetails" title={translations[language].changeContactDetails}>
                    <ChangeContacts />
                </Tab>*/}
                <Tab id="myPhoto" title={translations[language].myPhoto}>
                    <ChangePhoto />
                </Tab>
                <Tab id="changeLanguage" title={translations[language].changeLanguage}>
                    <ChangeLanguage />
                </Tab>
                <Tab id="deleteAccount" title={translations[language].deleteAccount}>
                    <DeleteAccount />
                </Tab>
            </div>
        </>
    )

    return contentPopupContainer ? contentPopupContainer : mainContent
}