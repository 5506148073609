import {useDispatch, useSelector} from "react-redux";
import getLanguage from "../../helpers/getLanguage";
import ValidateField from "../ValidateField/ValidateField";
import React, {useEffect} from "react";
import {
    creationAccountAddWorkplace, creationAccountDeleteWorkplace, creationAccountSubmit,
    getCitesGoogle, getHospitalsGoogle,
    showFieldsErrors,
    updateCreationAccountField, updateCreationAccountJobsFieldValue,
    updateFieldValidation
} from "../../store/actions";
import {ReactComponent as LocationIcon} from "../../images/location-icon.svg";
import {ReactComponent as WorkplaceIcon} from "../../images/workplace-icon.svg";
import {ReactComponent as DeleteIcon} from "../../images/delete-icon.svg";
import DropDown from "../DropDown/DropDown";
import {ReactComponent as PlusIcon} from "../../images/plus-icon.svg";
import waveEffect from "../../helpers/waveEffect";
import {ReactComponent as BackIcon} from "../../images/arrow-back-big-icon.svg";
import _ from "../../helpers/_";
import {creationAccountWork as translations} from "../../translations";
import {specializationTranslations} from "../../translations";
import '../../styles/search.css'
import {countryList} from "../../constants/countryList";
import getFlow from "../../helpers/getFlow";

export default () => {
    const dispatch = useDispatch()
    const doctorData = useSelector(state => state.app.doctorData)
    const validation = useSelector(state => state.validation.validation)
    const specialization = useSelector(state => state.creationAccount.specialization)
    const specializationOther = useSelector(state => state.creationAccount.specializationOther)
    const country = useSelector(state => state.creationAccount.country)
    const experience = useSelector(state => state.creationAccount.experience)
    const jobs = useSelector(state => state.creationAccount.jobs)
    const cites = useSelector(state => state.creationAccount.cites)
    const workplaces = useSelector(state => state.creationAccount.workplaces)
    const creationAccount = useSelector(state => state.creationAccount)
    const flow = getFlow(country);
    const isUsFlow = flow === 'us'

    const isStepValid = Object.values(validation).every(item => item.valid === true)
    const language = getLanguage()
    const texts = translations[language]

    useEffect(() => {
        doctorData.specialization && dispatch(updateCreationAccountField({key: 'specialization', value: doctorData.specialization}))
        doctorData.experience && dispatch(updateCreationAccountField({key: 'experience', value: doctorData.experience}))
        doctorData.jobs && dispatch(updateCreationAccountField({key: 'jobs', value: doctorData.jobs}))
    }, [])

    /*
    * Handlers
    * */
    const fieldChangeHandler = (key, pattern) => ({target: {value}}) => {
        if (pattern && !pattern.test(value)) {
            return
        }
        dispatch(updateCreationAccountField({ key, value }))
    }
    const fieldBlurHandler = key => ({target: {value}}) => {
        dispatch(updateCreationAccountField({ key, value: value.trim() }))
        const enableError = validation.hasOwnProperty(key) && !validation[key].showError
        if (enableError) {
            dispatch(updateFieldValidation({ id: key, showError: true }))
        }
    }
    const nestedFieldBlurHandler = (key, i) => ({target: {value}}) => {
        const fieldKey = key + i
        dispatch(updateCreationAccountJobsFieldValue(i, key, value.trim()))

        const enableError = validation.hasOwnProperty(fieldKey) && !validation[fieldKey].showError
        if (enableError) {
            dispatch(updateFieldValidation({ id: fieldKey, showError: true }))
        }
    }

    const cityClickHandler = i => ({target: {value}}) => {
        const fieldKey = 'city'
        if (value === '') dispatch(getCitesGoogle(fieldKey + i, 'a'))
        else dispatch(getCitesGoogle(fieldKey + i, value))
    }
    const workplaceClickHandler = (i, city) => ({target: {value}}) => {
        const fieldKey = 'institute'
        if (value === '') dispatch(getHospitalsGoogle('a', city, fieldKey + i))
        else dispatch(getHospitalsGoogle(value, city, fieldKey + i))
    }
    const cityChangeHandler = i => ({target: {value}}) => {
        const fieldKey = 'city'
        const enableError = validation.hasOwnProperty(fieldKey + i) && !validation[fieldKey + i].showError
        if (enableError) {
            dispatch(updateFieldValidation({id: fieldKey + i, showError: true}))
        }
        dispatch(updateCreationAccountJobsFieldValue(i, fieldKey, value))
        if (value === '') dispatch(updateCreationAccountField({ key: 'cites', value: [] }))
        else dispatch(getCitesGoogle(fieldKey + i, value))
    }
    const workplaceChangeHandler = (i, city) => ({target: {value}}) => {
        const fieldKey = 'institute'
        const enableError = validation.hasOwnProperty(fieldKey + i) && !validation[fieldKey + i].showError
        if (enableError) {
            dispatch(updateFieldValidation({id: fieldKey + i, showError: true}))
        }
        dispatch(updateCreationAccountJobsFieldValue(i, fieldKey, value))
        if (value === '') dispatch(updateCreationAccountField({ key: 'workplaces', value: [] }))
        else dispatch(getHospitalsGoogle(value, city, fieldKey + i))
    }
    const jobListItemClickHandler = (i, fieldKey, value) => e => {
        e.preventDefault()

        dispatch(updateCreationAccountField({ key: 'cites', value: [] }))
        dispatch(updateCreationAccountField({ key: 'workplaces', value: [] }))

        dispatch(updateCreationAccountJobsFieldValue(i, fieldKey, value))
    }

    const addWorkplaceHandler = e => {
        const id = Math.max( ...jobs.map(job => job.id)) + 1
        dispatch(creationAccountAddWorkplace(id, language))
        waveEffect(e)
    }
    const deleteWorkplaceHandler = i => () => dispatch(creationAccountDeleteWorkplace(i))

    const prevButtonClickHandler = () => dispatch(updateCreationAccountField({ key: 'currentStep', value: 0 }))
    const nextButtonClickHandler = () => {
        if (isStepValid) {
            const userData = {}
            userData.first_name = creationAccount.first_name
            userData.last_name = creationAccount.last_name
            creationAccount.phone_number && (userData.phone_number = creationAccount.phone_number)
            creationAccount.messenger && (userData.messenger = creationAccount.messenger)
            creationAccount.country && (userData.country = creationAccount.country)

            const doctorData = {}
            if (creationAccount.specialization) {
                const specializationValue = isUsFlow
                    ? specializationTranslations['us-en'][creationAccount.specialization]
                    : specializationTranslations[language][creationAccount.specialization]
                doctorData.specialization = creationAccount.specialization === 'other'
                    ? creationAccount.specializationOther
                    : specializationValue
            }

            creationAccount.experience && (doctorData.experience = Number(creationAccount.experience))
            doctorData.jobs = creationAccount.jobs

            dispatch(creationAccountSubmit({ userData, doctorData }))
        } else {
            dispatch(showFieldsErrors())
        }
    }

    /*
    * Prepare fields and validations
    * */
    const nextButtonClassName = isStepValid ? 'button-success' : 'button-disabled'

    // specialization
    const specializationList = isUsFlow ? specializationTranslations['us-en'] : specializationTranslations[language]
    const specializationClassName = specialization === 'disabled' ? 'tab-select tab-select--not-chosen' : 'tab-select'
    const specializationOptions = Object.entries(specializationList)
    const specializationValidationRules = isUsFlow
        ? [[specialization === 'disabled', '']]
        : []
    const specializationShowError = (validation && validation.specialization && validation.specialization.showError) || false
    const specializationOtherValidationRules = [[!/^[\D ]{3,50}$/.test(specializationOther), texts.specializationOtherErrorText]]
    const specializationOtherShowError = (validation && validation.specializationOther && validation.specializationOther.showError) || false
    const specializationInput = _(specialization === 'other',
        <ValidateField id="specializationOther" validation={specializationOtherValidationRules} showError={specializationOtherShowError}>
            <input type="text" value={specializationOther} className="tab-input"
                   onBlur={fieldBlurHandler('specializationOther')}
                   onChange={fieldChangeHandler('specializationOther')} />
        </ValidateField>)

    // experience
    const experienceCondition = experience !== '' && +experience > 0 && experience <100
    const experienceValidationRules = isUsFlow
        ? [[!experienceCondition, texts.workExperienceErrorText]]
        : []
    const experienceShowError = (validation && validation.experience && validation.experience.showError) || false

    // country
    const countryClassName = country === 'disabled' ? 'tab-select tab-select--not-chosen' : 'tab-select'
    const countryValidationRules = [[country === 'disabled', '']]
    const countryShowError = (validation && validation.country && validation.country.showError) || false

    // translations prepare
    const specializationText = isUsFlow ? texts.specialization : texts.ua_specialization
    const countryText = isUsFlow ? texts.country : texts.ua_country
    const cityText = isUsFlow ? texts.city : texts.ua_city
    const workplaceText = isUsFlow ? texts.workplace : texts.ua_workplace

    return (
        <>
            {/*specialization*/}
            <label className="tab-label">
                <div className="tab-label-text">
                    {specializationText}
                </div>
                <ValidateField id="specializationOther" validation={specializationValidationRules} showError={specializationShowError}>
                    <select name="specialization" className={specializationClassName}
                            value={specialization} onChange={fieldChangeHandler('specialization')}>
                        <option value='disabled' disabled>{texts.selectPlaceholder}</option>
                        {specializationOptions.map(
                            ([key, value]) => <option value={key} key={key}>{value}</option>
                        )}
                    </select>
                </ValidateField>
                {specializationInput}
            </label>

            {/*experience*/}
            {_(isUsFlow,
            <label htmlFor="experience" className="tab-label">
                <div className="tab-label-text">
                    {texts.workExperience}
                </div>
                <ValidateField id="experience" validation={experienceValidationRules} showError={experienceShowError}>
                    <input id="experience" type="tel" value={experience} className="tab-input" placeholder="00"
                           onBlur={fieldBlurHandler('experience')}
                           onChange={fieldChangeHandler('experience', /^\d{0,3}$|^\d{1,2}\.$|^\d{1,2}\.\d{1,2}$/)} />
                    <span className="work-experience-note">{texts.years}</span>
                </ValidateField>
            </label>)}

            <div className="creation-account-separator" />

            <div className="creation-account-section-title">{texts.workplaceTitle}</div>

            {/*country*/}
            <div className="creation-account-block">
                <label htmlFor="country" className="tab-label">
                    <div className="tab-label-text">
                        {countryText}
                    </div>
                    <ValidateField id="country" validation={countryValidationRules} showError={countryShowError}>
                        <select name="country" id="country" className={countryClassName}
                                value={country} onChange={fieldChangeHandler('country')}>
                            <option value='disabled' disabled>{texts.selectPlaceholder}</option>
                            {Object.entries(countryList).map(
                                ([key, value]) => <option value={key} key={key}>{value}</option>
                            )}
                        </select>
                    </ValidateField>
                </label>
            </div>

            {/*jobs*/}
            {jobs.map((job, i) => {
                const id = job.id
                const key = 'job' + i + id

                /*
                * City
                * */
                const city = job.city.split(',')[0]
                const cityFieldKey = 'city' + i
                const cityListCondition = cites.hasOwnProperty(cityFieldKey) && cites[cityFieldKey].length
                const cityList = cityListCondition
                    ? cites[cityFieldKey].map(fc => {
                        //const title = fc.title.replace(/, Украина|, Ukraine|, Україна/gi, '')
                        let title = fc.title.split(',').map(val => val.trim())
                        if (title.length > 2) {
                            title.pop()
                            title = title.join(', ')
                        } else {
                            title = title[0]
                        }

                        return (
                            <div className="search-result-item" key={key + title} onClick={jobListItemClickHandler(i, 'city', title)}>
                                <div className="search-result-img-wrapper"><LocationIcon/></div>
                                <div>
                                    <div className="search-result-title">{title}</div>
                                    {/*<div className="search-result-subtitle">{texts.country}</div>*/}
                                    {/*<div className="search-result-subtitle">{rest.length ? rest[rest.length - 1] : ''}</div>*/}
                                    <div className="search-result-subtitle">{countryList[country]}</div>
                                </div>
                            </div>
                        )
                    }) : null

                /*
                * Workplace
                * */
                const workplace = job.institute
                const workplaceFieldKey = 'institute' + i
                const workplacesListCondition = workplaces.hasOwnProperty(workplaceFieldKey) && workplaces[workplaceFieldKey].length
                const workplacesList = workplacesListCondition
                    ? workplaces[workplaceFieldKey].map((fc, index) => (
                        <div className="search-result-item" key={key + fc.address + index} onClick={jobListItemClickHandler(i, 'institute', fc.title)}>
                            <div className="search-result-img-wrapper"><WorkplaceIcon /></div>
                            <div>
                                <div className="search-result-title">{fc.title}</div>
                                <div className="search-result-subtitle">{countryList[country]}</div>
                            </div>
                        </div>
                    )) : null

                /*
                * Delete button
                * */
                const deleteButtonCondition = jobs.length > 1
                const deleteButton = deleteButtonCondition ? <button className="delete-workplace" onClick={deleteWorkplaceHandler(i)}><DeleteIcon />{texts.deleteWorkplace}</button> : null

                return (
                    <div className="creation-account-block" key={key}>

                        {/*city*/}
                        <label htmlFor={cityFieldKey} className={`tab-label ${country === 'disabled' ? 'events-none' : ''}`}>
                            <div className="tab-label-text">{cityText}</div>

                            <DropDown condition={cityListCondition} className="jobs-search">
                                <DropDown.Element>
                                    <ValidateField id={cityFieldKey} validation={isUsFlow ? [[city === '', '']] : []} showError={(validation && validation[cityFieldKey] && validation[cityFieldKey].showError) || false}>
                                        <input id={cityFieldKey} type="text" value={city} className="tab-input" autoComplete="off"
                                               placeholder={texts.cityPlaceholder}
                                               onBlur={nestedFieldBlurHandler('city', i)}
                                               onClick={cityClickHandler(i)}
                                               onChange={cityChangeHandler(i)} />
                                    </ValidateField>
                                </DropDown.Element>
                                <DropDown.List>
                                    <div className="search-result-container-wrapper">
                                        <div className="search-result-container">{cityList}</div>
                                    </div>
                                </DropDown.List>
                            </DropDown>
                        </label>

                        {/*workplace*/}
                        <label htmlFor={workplaceFieldKey} className={`tab-label ${city ? '' : 'events-none'}`}>
                            <div className="tab-label-text">{workplaceText}</div>

                            <DropDown condition={workplacesListCondition} className="jobs-search">
                                <DropDown.Element>
                                    <ValidateField id={workplaceFieldKey} validation={isUsFlow ? [[workplace === '', '']] : []} showError={(validation && validation[workplaceFieldKey] && validation[workplaceFieldKey].showError) || false}>
                                        <input id={workplaceFieldKey} type="text" value={workplace} className="tab-input" autoComplete="off"
                                               placeholder={texts.workplacePlaceholder}
                                               onBlur={nestedFieldBlurHandler('institute', i)}
                                               onClick={workplaceClickHandler(i, city)}
                                               onChange={workplaceChangeHandler(i, city)} />
                                    </ValidateField>
                                </DropDown.Element>
                                <DropDown.List>
                                    <div className="search-result-container-wrapper">
                                        <div className="search-result-container">{workplacesList}</div>
                                    </div>
                                </DropDown.List>
                            </DropDown>
                        </label>

                        {deleteButton}
                    </div>
                )
            })}

            {/*add workplace*/}
            <div>
                <button className="button button-outline-success full-width" onClick={addWorkplaceHandler}>
                    <PlusIcon className="creation-account-back-icon" />
                    {texts.addWorkPlaceButtonText}
                </button>
            </div>

            {/*controls*/}
            <div className="creation-account-controls-wrapper">
                <button className={`button button-outline-secondary`} onClick={prevButtonClickHandler}>
                    <BackIcon className="creation-account-back-icon" />{texts.back}
                </button>
                <button className={`button ${nextButtonClassName}`} onClick={nextButtonClickHandler}>
                    {texts.submit}
                </button>
            </div>
        </>
    )
}