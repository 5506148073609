import React, {useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux"
import {
    addWorkplace,
    deleteWorkplace,
    setActiveTab,
    updateJobsFieldValue,
    setSettingData,
    setSettingTabData,
    showNotification,
    updateFieldValidation,
    updateDoctor, showFieldsErrors, getCitesGoogle, getHospitalsGoogle, updateSettingsField
} from "../../store/actions"
import ValidateField from "../ValidateField/ValidateField"
import DropDown from "../DropDown/DropDown"
import waveEffect from "../../helpers/waveEffect"
import './ChangeCredentials.css'
import '../../styles/search.css'
import {ReactComponent as DeleteIcon} from '../../images/delete-icon.svg'
import {ReactComponent as AddWorkplaceIcon} from '../../images/add-workplace-icon.svg'
import {ReactComponent as PlusIcon} from "../../images/plus-icon.svg"
import {ReactComponent as LocationIcon} from "../../images/location-icon.svg"
import {ReactComponent as WorkplaceIcon} from "../../images/workplace-icon.svg"
import scrollToFirstError from "../../helpers/scrollToFirstError";
import {changeCredentials as translations} from "../../translations";
import _ from "../../helpers/_";
import getFlow from "../../helpers/getFlow";


const translationsOther = {
    en: 'Other',
    ru: 'Другое',
    uk: 'Інше',
}

const isDefaultValue = (object, key) => object.hasOwnProperty(key)
const useGetDefaultValue = (condition, ifTrue) => {
    const language = useSelector(state => state.app.lang)
    return condition ? ifTrue : translationsOther[language]
}
const useGetOptionsList = (object) => {
    const language = useSelector(state => state.app.lang)
    let options = []
    options = Object.entries(object)
    options.push([translationsOther[language], translationsOther[language]])
    return options
}

export default () => {
    const tabKey = 'changeCredentials'
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const doctorData = useSelector(state => state.app.doctorData)
    const tabData = useSelector(state => state.settings.temp[tabKey])
    const cites = useSelector(state => state.settings.cites)
    const workplaces = useSelector(state => state.settings.workplaces)
    const validation = useSelector(state => state.validation.validation)
    const texts = translations[language]
    const fullName = doctorData.first_name + ' ' + doctorData.last_name
    const country = useSelector(state => state.app.doctorData.country)

    const { specialization, specializationOther, experience, jobs, } = tabData

    useEffect(() => {
        const data = {
            specialization: doctorData.specialization,
            specializationOther: doctorData.specialization,
            experience: doctorData.experience,
            jobs: doctorData.jobs || [],
        }
        dispatch(setSettingTabData(tabKey, data))
    }, [])

    /*
    * Handlers
    * */
    const fieldChangeHandler = (fieldKey, pattern) => ({target: {value}}) => {
        const enableError = validation.hasOwnProperty(fieldKey) && !validation[fieldKey].showError
        if (enableError) {
            dispatch(updateFieldValidation({id: fieldKey, showError: true}))
        }
        if (pattern && !pattern.test(value)) {
            return
        }
        dispatch(setSettingData(tabKey, fieldKey, value))
    }

    const cityClickHandler = i => ({target: {value}}) => {
        const fieldKey = 'city'
        if (value === '') dispatch(getCitesGoogle(fieldKey + i, 'a'))
        else dispatch(getCitesGoogle(fieldKey + i, value))
    }
    const workplaceClickHandler = (i, city) => ({target: {value}}) => {
        const fieldKey = 'institute'
        if (value === '') dispatch(getHospitalsGoogle('a', city, fieldKey + i))
        else dispatch(getHospitalsGoogle(value, city, fieldKey + i))
    }
    const cityChangeHandler = i => ({target: {value}}) => {
        const fieldKey = 'city'
        const enableError = validation.hasOwnProperty(fieldKey + i) && !validation[fieldKey + i].showError
        if (enableError) {
            dispatch(updateFieldValidation({id: fieldKey + i, showError: true}))
        }
        dispatch(updateJobsFieldValue(i, fieldKey, value))
        if (value === '') dispatch(updateSettingsField('cites', []))
        else dispatch(getCitesGoogle(fieldKey + i, value))
    }
    const workplaceChangeHandler = (i, city) => ({target: {value}}) => {
        const fieldKey = 'institute'
        const enableError = validation.hasOwnProperty(fieldKey + i) && !validation[fieldKey + i].showError
        if (enableError) {
            dispatch(updateFieldValidation({id: fieldKey + i, showError: true}))
        }
        dispatch(updateJobsFieldValue(i, fieldKey, value))
        if (value === '') dispatch(updateSettingsField('workplaces', []))
        else dispatch(getHospitalsGoogle(value, city, fieldKey + i))
    }
    const jobListItemClickHandler = (i, fieldKey, value) => e => {
        e.preventDefault()

        dispatch(updateSettingsField('cites', []))
        dispatch(updateSettingsField('workplaces', []))

        dispatch(updateJobsFieldValue(i, fieldKey, value))
    }

    const addWorkplaceHandler = e => {
        const id = Math.max( ...jobs.map(job => job.id)) + 1
        dispatch(addWorkplace(id, language))
        waveEffect(e)
    }
    const deleteWorkplaceHandler = i => () => {
        dispatch(deleteWorkplace(i))
        dispatch(showNotification('', texts.deleteWorkplaceNotificationText))
    }

    const saveButtonHandler = e => {
        waveEffect(e)
        const tabInvalid = Object.values(validation).find(item => item.valid === false)
        if (tabInvalid) {
            dispatch(showFieldsErrors())
            scrollToFirstError()
        } else {
            const specializationValue = texts.specializationOptions.hasOwnProperty(specialization)
                ? specialization
                : specializationOther
            const data = { specialization: specializationValue, experience, jobs }
            dispatch(updateDoctor(data))
                .then(() => {
                    dispatch(showNotification('', texts.changesSaved))
                    dispatch(setActiveTab(''))
                })
        }
    }

    /*
    * Prepare fields and validations
    * */
    // specialization
    const isDefaultSpecialization = isDefaultValue(texts.specializationOptions, specialization)
    const specializationDefaultValue = useGetDefaultValue(isDefaultSpecialization, specialization)
    const specializationOptions = useGetOptionsList(texts.specializationOptions)
    const specializationValidationRules = [[!/^[\D ]{3,50}$/.test(specializationOther), texts.specializationOtherErrorText]]
    const specializationShowError = (validation && validation.specializationOther && validation.specializationOther.showError) || false
    const specializationInput = isDefaultSpecialization ? null :
        <ValidateField id="specializationOther" validation={specializationValidationRules} showError={specializationShowError}>
            <input type="text" value={specializationOther} className="tab-input"
                   onChange={fieldChangeHandler('specializationOther')} />
        </ValidateField>

    // experience
    const experienceCondition = experience !== '' && +experience > 0 && experience <100
    const experienceValidationRules = [[!experienceCondition, texts.workExperienceErrorText]]
    const experienceShowError = (validation && validation.experience && validation.experience.showError) || false

    if (tabData.loaded === false) return <div />
    return (
        <>
            <div className="tab-section">

                {/*specialization*/}
                <label htmlFor="specialization" className="tab-label">
                    <div className="tab-label-text">
                        {texts.specialization}
                    </div>
                    <select name="specialization" id="specialization" className="tab-select"
                            value={specializationDefaultValue} onChange={fieldChangeHandler('specialization')}>
                        {specializationOptions.map(
                            ([key, value]) => <option value={key} key={key}>{value}</option>
                        )}
                    </select>
                    {specializationInput}
                </label>

                {/*experience*/}
                {_(getFlow(country) === 'us',
                    <label htmlFor="experience" className="tab-label">
                        <div className="tab-label-text">
                            {texts.workExperience}
                        </div>
                        <ValidateField id="experience" validation={experienceValidationRules} showError={experienceShowError}>
                            <input id="experience" type="tel" value={experience} className="tab-input"
                                   onChange={fieldChangeHandler('experience', /^\d{0,3}$|^\d{1,2}\.$|^\d{1,2}\.\d{1,2}$/)} />
                            <span className="work-experience-note">{texts.years}</span>
                        </ValidateField>
                    </label>
                )}
            </div>

            {/*jobs*/}
            {jobs.map((job, i) => {
                const id = job.id
                const key = 'job' + i + id

                /*
                * City
                * */
                const city = job.city.split(',')[0]
                const cityFieldKey = 'city' + i
                const cityListCondition = cites.hasOwnProperty(cityFieldKey) && cites[cityFieldKey].length
                const cityList = cityListCondition
                    ? cites[cityFieldKey].map(fc => {
                        const title = fc.title.replace(/, Украина|, Ukraine|, Україна/gi, '')
                        return (
                            <div className="search-result-item" key={key + title} onClick={jobListItemClickHandler(i, 'city', title)}>
                                <div className="search-result-img-wrapper"><LocationIcon/></div>
                                <div>
                                    <div className="search-result-title">{title}</div>
                                    <div className="search-result-subtitle">{texts.country}</div>
                                </div>
                            </div>
                        )
                    }) : null

                /*
                * Workplace
                * */
                const workplace = job.institute
                const workplaceFieldKey = 'institute' + i
                const workplacesListCondition = workplaces.hasOwnProperty(workplaceFieldKey) && workplaces[workplaceFieldKey].length
                const workplacesList = workplacesListCondition
                    ? workplaces[workplaceFieldKey].map(fc => (
                        <div className="search-result-item" key={Math.random()} onClick={jobListItemClickHandler(i, 'institute', fc.title)}>
                            <div className="search-result-img-wrapper"><WorkplaceIcon /></div>
                            <div><div className="search-result-title">{fc.title}</div><div className="search-result-subtitle">{texts.country}</div></div>
                        </div>
                    )) : null

                /*
                * Delete button
                * */
                const deleteButtonCondition = jobs.length > 1
                const deleteButton = deleteButtonCondition ? <button className="delete-workplace" onClick={deleteWorkplaceHandler(i)}><DeleteIcon />{texts.deleteWorkplace}</button> : null

                return (
                    <div className="tab-section" key={key}>

                        {/*city*/}
                        <label htmlFor={cityFieldKey} className="tab-label">
                            <div className="tab-label-text">{texts.city}</div>

                            <DropDown condition={cityListCondition} className="jobs-search">
                                <DropDown.Element>
                                    <ValidateField id={cityFieldKey} validation={[[city === '', '']]} showError={(validation && validation[cityFieldKey] && validation[cityFieldKey].showError) || false}>
                                        <input id={cityFieldKey} type="text" value={city} className="tab-input" autoComplete="off"
                                               onClick={cityClickHandler(i)}
                                               onChange={cityChangeHandler(i)} />
                                    </ValidateField>
                                </DropDown.Element>
                                <DropDown.List>
                                    <div className="search-result-container-wrapper">
                                        <div className="search-result-container">{cityList}</div>
                                    </div>
                                </DropDown.List>
                            </DropDown>
                        </label>

                        {/*workplace*/}
                        <label htmlFor={workplaceFieldKey} className="tab-label">
                            <div className="tab-label-text">{texts.workplace}</div>

                            <DropDown condition={workplacesListCondition} className="jobs-search">
                                <DropDown.Element>
                                    <ValidateField id={workplaceFieldKey} validation={[[workplace === '', '']]} showError={(validation && validation[workplaceFieldKey] && validation[workplaceFieldKey].showError) || false}>
                                        <input id={workplaceFieldKey} type="text" value={workplace} className="tab-input" autoComplete="off"
                                               onClick={workplaceClickHandler(i, city)}
                                               onChange={workplaceChangeHandler(i, city)} />
                                    </ValidateField>
                                </DropDown.Element>
                                <DropDown.List>
                                    <div className="search-result-container-wrapper">
                                        <div className="search-result-container">{workplacesList}</div>
                                    </div>
                                </DropDown.List>
                            </DropDown>
                        </label>

                        {deleteButton}
                    </div>
                )
            })}

            <div className="tab-section">

                {/*add workplace*/}
                <div className="add-workplace-wrapper">
                    <AddWorkplaceIcon /> <span className="add-workplace-text">{texts.addWorkPlaceText(fullName)}</span>
                </div>
                <button className="tab-button tab-button--transparent" onClick={addWorkplaceHandler}>
                    <PlusIcon />
                    <div className="add-workplace-button-text">{texts.addWorkPlaceButtonText}</div>
                </button>
            </div>

            <div className="tab-section">

                {/*save button*/}
                <button className="tab-button tab-button--save" onClick={saveButtonHandler}>{texts.save}</button>
            </div>
        </>
    )
}