import './CreationAccount.css'
import {ReactComponent as LogoIcon} from "../../images/logo-initial-screen-icon.svg";
import getLanguage from "../../helpers/getLanguage";
import {useDispatch, useSelector} from "react-redux";
import {creationAccount as translations} from "../../translations";
import React, {useEffect} from "react";
import CreationAccountStep1 from "../../components/CreationAccountAcquaintance/CreationAccountAcquaintance";
import CreationAccountStep2 from "../../components/CreationAccountContacts/CreationAccountContacts";
import CreationAccountStep3 from "../../components/CreationAccountWork/CreationAccountWork";
import CreationAccountStep4 from "../../components/CreationAccountPayments/CreationAccountPayments";
import Notification from "../../components/Notification/Notification";
import _ from "../../helpers/_";
import RegistrationSuccessful from "../../components/RegistrationSuccessful/RegistrationSuccessful";
import {signIn} from "../../store/actions";
import jsonParse from "../../helpers/jsonParse";
import getFlow from "../../helpers/getFlow";


export default () => {
    const dispatch = useDispatch()
    const currentStep = useSelector(state => state.creationAccount.currentStep)
    const contentPopup = useSelector(state => state.app.contentPopup)
    const isShowNotification = useSelector(state => state.app.notification.show)
    const country = useSelector(state => state.creationAccount.country)
    const otpUaFlow = useSelector(state => state.app.otpUaFlow)
    const notification = _(isShowNotification, <Notification />)
    const language = getLanguage()
    const texts = translations[language]
    const isUsFlow = otpUaFlow === false

    useEffect(() => {
        const data = localStorage.getItem('cbm_loginData')
        if (data) {
            const parsedData = jsonParse(data)
            if (parsedData) {
                parsedData.username = parsedData.email
                dispatch(signIn(parsedData))
            }
        }
    },[])

    const data = localStorage.getItem('cbm')
    if (data) localStorage.removeItem('cbm_loginData')

    // @todo
    /*const contentTitle = isUsFlow
        ? [
            texts.information,
            texts.contacts,
            texts.credentials,
        ]
        : [
            texts.information,
            texts.credentials,
        ]*/
    const contentTitle = [
        texts.information,
        texts.credentials,
    ]

    /*
    * Components
    * */
    // @todo
    /*const steps = isUsFlow
        ?   [
                <CreationAccountStep1 />,
                <CreationAccountStep2 />,
                <CreationAccountStep3 />
            ]
        :   [
                <CreationAccountStep1 />,
                <CreationAccountStep3 />
            ]*/
    const steps = [
        <CreationAccountStep1 />,
        <CreationAccountStep3 />
    ]

    const gradientWidth = 480 / steps.length * (currentStep + 1)

    /*
    * Returns
    * */
    if (contentPopup === 'registration_successful') {
        return <RegistrationSuccessful />
    }
    return (
        <div className="creation-account-wrapper">
            <LogoIcon />

            <div className="creation-account-content-wrapper">
                {/*header*/}
                <div className="creation-account-content-header">
                    <div className="creation-account-content-title">
                        {contentTitle[currentStep]}
                    </div>
                    <div className="creation-account-content-step-info">
                        {texts.stepInfo(currentStep, steps.length)}
                    </div>
                </div>

                {/*progress*/}
                <div className={`creation-account-progressbar creation-account-progressbar--${currentStep}`} >
                    <div className="creation-account-progressbar-gradient" style={{width: `${gradientWidth}px`}}/>
                </div>

                {/*step*/}
                <div className={`creation-account-step-wrapper creation-account-step-wrapper--${currentStep}`}>
                    {steps[currentStep]}
                </div>

                {/*ledge*/}
                <div className="creation-account-ledge" />
            </div>

            {notification}
        </div>
    )
}