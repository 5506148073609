import {
    DELETE_PATIENT,
    FILTERED_BY_SEARCH_PATIENTS,
    FILTERED_PATIENTS, UPDATE_PATIENT_DATA,
    GET_PATIENTS, LOGOUT,
    PATIENTS_SEARCH_VALUE,
    RESTORE_STORE, UPDATE_FIELD, UPDATE_MY_PATIENTS_FIELD
} from "./types"

const initialState = {
    patientsCount: 0,
    patients: {
        all: [],
        filtered: [],
        filteredBySearch:{
            fullNameFiltered: [],
            diagnosisFiltered: [],
        },
        programs: [
            {
                id: 209,
                currentDay: 0,
                endDate: 36,
                currentLoad: null,
                compliance: null
            },
            {
                id: 210,
                currentDay: 0,
                endDate: 32,
                currentLoad: null,
                compliance: null
            },
            {
                id: 211,
                currentDay: 12,
                endDate: 36,
                currentLoad: 50,
                compliance: 35
            },
            {
                id: 212,
                currentDay: 36,
                endDate: 36,
                currentLoad: 70,
                compliance: 87
            },
            {
                id: 213,
                currentDay: 36,
                endDate: 36,
                currentLoad: 85,
                compliance: 93
            },
            {
                id: 231,
                currentDay: 0,
                endDate: 28,
                currentLoad: null,
                compliance: null,
            },
        ],
    },
    statuses: {
        available: [],
        enabled: ['start_at', 'in_progress', 'completed', 'canceled'],
    },
    search: '',
    loaded: false,
}

export const myPatientsReducer = (state = initialState, action) => {
    const payload = action.payload
    switch (action.type) {
        case UPDATE_MY_PATIENTS_FIELD: {
            const key = action.payload.key
            const value = action.payload.value
            return { ...state,
                [key]: value,
            }
        }
        case LOGOUT:
            return { ...initialState,
                windowWidth: state.windowWidth,
            }
        case RESTORE_STORE:
            return { ...action.payload.myPatients }
        case GET_PATIENTS:
            if (action.payload.data) {
                action.payload.data.forEach(patient => {
                    patient.status = patient.status === 'payment' || patient.status === 'awaiting_start'
                        ? 'start_at'
                        : patient.status
                })
                const availableStatuses = [...new Set(action.payload.data
                    .map(patient => patient.status))]

                return {
                    ...state,
                    patientsCount: action.payload.count,
                    patients: {
                        ...state.patients,
                        all: action.payload.data,
                        filtered: action.payload.data,
                    },
                    statuses: {
                        ...state.statuses,
                        available: availableStatuses,
                        enabled: [],
                    },
                    loaded: true,
                }
            }
            return state
        case FILTERED_PATIENTS:
            return { ...state,
                patients: { ...state.patients,
                    filtered: action.payload.patientsFiltered,
                },
                statuses: { ...state.statuses,
                    enabled: action.payload.statusesEnabled,
                },
            }
        case PATIENTS_SEARCH_VALUE:
            return { ...state,
                search: action.payload,
            }
        case FILTERED_BY_SEARCH_PATIENTS:
            return { ...state,
                patients: { ...state.patients,
                    filteredBySearch: {
                        fullNameFiltered: action.payload.fullNameFiltered,
                        diagnosisFiltered: action.payload.diagnosisFiltered,
                    },
                },
            }
        case DELETE_PATIENT:
            if (action.payload) {
                const patients = state.patients.all.filter(patient => patient.id !== action.payload)
                const patientsFiltered = state.patients.filtered.filter(patient => patient.id !== action.payload)
                const availableStatuses = patients.map(patient => patient.status)
                return { ...state,
                    patientsCount: patients.length,
                    patients: { ...state.patients,
                        all: patients,
                        filtered: patientsFiltered,
                    },
                    statuses: { ...state.statuses,
                        available: availableStatuses,
                    },
                }
            }
            return state
        default: return state
    }
}