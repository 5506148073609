import './DropDown.css'

const DropDown = props => {
    const condition = props.condition
    const children = props.children
    const wrapperClassName = props.className
        ? `drop-down-wrapper ${props.className}`
        : 'drop-down-wrapper'

    let content = []
    if (children && children.length) {
        content.push(children[0])
        if (children.length === 2 && condition) {
            content.push(children[1])
        }
    }

    return (
        <div className={wrapperClassName}>
            {content}
        </div>
    )
}
DropDown.Element = props => (props && props.children) || null
DropDown.List = props => (props && props.children) || null

export default DropDown