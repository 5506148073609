import Popup from "../Popup/Popup";
import React, {createRef, useEffect, useState} from "react";
import generateArray from "../../helpers/generateArray";
import {useDispatch, useSelector} from "react-redux";
import './ChangeCurrentLoad.css'
import {changeCurrentLoad, closePopup} from "../../store/actions";
import {changeCurrentLoad as translations} from "../../translations";
import waveEffect from "../../helpers/waveEffect";
import _ from "../../helpers/_";
import {ReactComponent as LoadingButton} from "../../images/loading-button.svg";


export default () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const requestInProgress = useSelector(state => state.app.requestInProgress)
    const patient = useSelector(state => state.patient)
    const currentLoad = patient.patientData
        ? +(patient.patientData.current_program.current_load).toFixed(0)
        : 0
    const [currentLoadState, currentLoadStateSChange] = useState(currentLoad)
    const texts = translations[language]

    const saveButtonClassName = requestInProgress ? 'tab-button button-disabled remove-account' : 'tab-button tab-button--save remove-account'

    const wrapperRef = createRef()
    const buttonRef = createRef()

    const loadList = generateArray(10, 70, 5)

    const max = 330 - 32
    const stepWeight = max / 12
    const steps = []
    for (let i = 0; i <= max; i += stepWeight ) {
        steps.push(i)
    }
    useEffect(() => {
        let initialPosition
        if (currentLoadState < 10 ) {
            initialPosition = 0
        } else if (currentLoadState > 70) {
            initialPosition = 298
        } else {
            initialPosition = (currentLoadState - 10) * 4.97
        }

        const position = initialPosition
        const closest = steps.reduce((prev, curr) =>
            Math.abs(curr - position) < Math.abs(prev - position) ? curr : prev)
        buttonRef.current.style.left = closest + 'px'
    }, [])

    /*
    * Handlers
    * */
    const progressButtonMouseDownHandler = e => {
        const element = e.target
        const {left, width} = wrapperRef.current.getBoundingClientRect()
        const min = 0
        const max = width - 32
        const stepWeight = max / 12
        const steps = []
        for (let i = 0; i <= max; i += stepWeight ) {
            steps.push(i)
        }
        const isTouch = e.type === 'touchmove'
        const pageX = isTouch ? e.touches['0'].pageX : e.pageX
        moveAt(pageX)
        function moveAt(pageX) {
            const position = Math.max(Math.min(pageX - left - 16, max), min)

            const closest = steps.reduce((prev, curr) =>
                Math.abs(curr - position) < Math.abs(prev - position) ? curr : prev)

            element.style.left = closest + 'px'
            const index = steps.indexOf(closest)
            currentLoadStateSChange(loadList[index])
        }

        const onMouseMove = e => moveAt(e.pageX)
        if (!isTouch) {
            document.addEventListener('mousemove', onMouseMove)
            document.addEventListener('mouseup', () =>
                document.removeEventListener('mousemove', onMouseMove))
        }
    }
    const saveClickHandler = e => {
        if (!requestInProgress) {
            waveEffect(e)
            dispatch(changeCurrentLoad(patient.patientData.patient_id, currentLoadState))
        }
    }
    const cancelClickHandler = () => dispatch(closePopup())

    return (
        <Popup>
            <div className="popup-title current-load-style">{texts.setCurrentLoad}</div>
            <div className="current-load">{currentLoadState}%</div>
            <div className="progress-wrapper" ref={wrapperRef}>
                <button className="progress-button" ref={buttonRef} onMouseDown={progressButtonMouseDownHandler} onTouchMove={progressButtonMouseDownHandler}/>
            </div>
            <div className="current-load-percents-wrapper">
                <div>10%</div>
                <div>70%</div>
            </div>
            <div className="change-load-notice">{texts.notice}</div>
            <button className={saveButtonClassName} onClick={saveClickHandler}>
                {texts.save}
                {_(requestInProgress, <LoadingButton />)}
            </button>
            <div className="remove-account-cancel" onClick={cancelClickHandler}>{texts.cancel}</div>
        </Popup>
    )
}