import {NavLink} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import './MainNav.css'
import {ReactComponent as PatientsIcon} from '../../images/nav-item-patients.svg'
import {ReactComponent as ConsultationsIcon} from '../../images/consultations-icon.svg'
import {ReactComponent as NotificationsIcon} from '../../images/notifications-icon.svg'
import {ReactComponent as SettingsIcon} from '../../images/nav-item-settings.svg'
import {ReactComponent as SupportIcon} from '../../images/nav-item-support.svg'
import {mainNav as translations} from "../../translations";
import {toggleMenuStatus} from "../../store/actions";
import _ from "../../helpers/_";
import getFlow from "../../helpers/getFlow";


export default () => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const windowWidth = useSelector(state => state.app.windowWidth)
    const consultations = useSelector(state => state.app.consultations)
    const notifications = useSelector(state => state.app.notifications)
    const country = useSelector(state => state.app.doctorData.country)

    const lastConsultationDate = localStorage.getItem('cbm_consultations_date') || null
    const consultationsCount = consultations && consultations.count && consultations.data.filter(consultation => new Date(lastConsultationDate) < new Date(consultation.order_time))
    const consultationsCountComponent = (consultationsCount && consultationsCount.length && <div className="nav-item-count">{consultationsCount.length}</div>) || null

    const lastNotificationDate = localStorage.getItem('cbm_notifications_date') || null
    const notificationsCount = notifications && notifications.count && notifications.data.filter(notification => new Date(lastNotificationDate) < new Date(notification.modified))
    const notificationsCountComponent = (notificationsCount && notificationsCount.length && <span className="nav-item-count">{notificationsCount.length}</span>) || null

    const isShowIcon = windowWidth > 1300

    const toggleMenuStatusHandler = () => dispatch(toggleMenuStatus())

    return (
        <nav className="main-nav">
            <NavLink to="/patients" className="nav-item" activeClassName="nav-item--active" onClick={toggleMenuStatusHandler}>
                {isShowIcon ? <PatientsIcon/> : null}
                {translations[language].myPatients}
            </NavLink>
            {/*_(getFlow(country) === 'standard',
                <NavLink to="/consultations" className="nav-item" activeClassName="nav-item--active" onClick={toggleMenuStatusHandler}>
                    {isShowIcon ? <ConsultationsIcon/> : null}
                    {translations[language].consultations}
                    {consultationsCountComponent}
                </NavLink>)*/}
            {/*<NavLink to="/notifications" className="nav-item" activeClassName="nav-item--active" onClick={toggleMenuStatusHandler}>
                    {isShowIcon ? <NotificationsIcon/> : null}
                    {translations[language].notifications}
                    {notificationsCountComponent}
                </NavLink>*/}
            <NavLink to="/settings" className="nav-item" activeClassName="nav-item--active" onClick={toggleMenuStatusHandler}>
                {isShowIcon ? <SettingsIcon /> : null}
                {translations[language].settings}
            </NavLink>
            <NavLink to="/support" className="nav-item" activeClassName="nav-item--active" onClick={toggleMenuStatusHandler}>
                {isShowIcon ? <SupportIcon /> : null}
                {translations[language].support}
            </NavLink>
        </nav>
    )
}