import {CLOSE_POPUP, SET_CONTENT_POPUP, SET_POPUP} from "./types";

const initialState = {
    popup: '',
    contentPopup: '',
}

export const popupReducer = (state = initialState, action = {}) => {
    const payload = action.payload
    switch (action.type) {
        case SET_CONTENT_POPUP:
            return { ...state,
                contentPopup: payload
            }
        case SET_POPUP:
            return { ...state,
                popup: payload
            }
        case CLOSE_POPUP: {
            return { ...state,
                popup: ''
            }
        }
        default: return state
    }
}