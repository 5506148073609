import {
    UPDATE_DOCTOR_DATA,
    GET_NOTIFICATIONS,
    GET_CONSULTATIONS,
    GET_HOSPITALS_GOOGLE,
    GET_CITES_GOOGLE,
    SET_SCROLL_OFFSET,
    UPDATE_FIELD,
    SET_CONTENT_POPUP,
    SET_POPUP,
    UPDATE_CONTACTS,
    UPDATE_PHOTO_ERROR,
    REMOVE_PHOTO,
    UPLOAD_PHOTO,
    SHOW_FIELDS_ERRORS,
    UNREGISTER_FIELD_VALIDATION,
    UPDATE_FIELD_VALIDATION,
    GET_HOSPITALS,
    UPDATE_JOBS_FIELD_VALUE,
    ADD_WORKPLACE,
    DELETE_WORKPLACE,
    UPDATE_DOCTOR,
    GET_CITES,
    SET_SETTING_DATA,
    SET_SETTING_TAB_DATA,
    SET_ACTIVE_TAB,
    TOGGLE_MENU_STATUS,
    FILTERED_BY_SEARCH_PATIENTS,
    FILTERED_PATIENTS,
    GET_PATIENTS,
    LOGIN,
    LOGOUT,
    PATIENTS_SEARCH_VALUE,
    RESTORE_STORE,
    DELETE_PATIENT,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    SET_WINDOW_WIDTH,
    REQUEST_START,
    REQUEST_END,
    UPDATE_MY_PATIENTS_FIELD,
    UPDATE_PATIENT_FIELD,
    SET_INITIAL_PATIENT_STATE,
    CHANGE_CONTROL_TYPE,
    UPDATE_STEP_INDEX,
    CLOSE_POPUP,
    UPDATE_CREATION_ACCOUNT_FIELD,
    UPDATE_SETTINGS_FIELD,
    UPDATE_CREATION_ACCOUNT_JOBS_FIELD_VALUE,
    CREATION_ACCOUNT_ADD_WORKPLACE,
    CREATION_ACCOUNT_DELETE_WORKPLACE,
    GET_DAYS_DATA,
    UPDATE_PATIENT_DATA,
    PUSH_DAY_DATA,
    ADD_PATIENT_SHOW_COMPONENT,
    ADD_PATIENT_HIDE_COMPONENT,
    ADD_PATIENT_NEXT_STEP,
    ADD_PATIENT_PREV_STEP,
    ADD_PATIENT_UPDATE_FIELD,
    ADD_PATIENT_INCREASE_WEEKS,
    ADD_PATIENT_DECREASE_WEEKS,
    ADD_PATIENT_INITIAL_STATE,
    ADD_PATIENT_ONE_MORE,
} from "./types"
import {API_BASE_URL} from "../constants/appConstants";
import middleware from "../helpers/middleware";


const requestStart = () => ({ type: REQUEST_START })

const requestEnd = () => ({ type: REQUEST_END })

export const acceptOtp = otp => middleware(async (dispatch, getState) => {
    const { app: { lang, otpPhoneNumber, otpToken } } = getState()
    //const phone = otpPhoneNumber.replace(/\+/g, '')
    const phone = encodeURIComponent(otpPhoneNumber)
    const response = await fetch(`${API_BASE_URL}/users/accept_otp?otp=${otp}&token=${otpToken}&device_id=${phone}`,{
        method: 'post',
        headers: {
            'Accept-Language': lang,
        },
    })
    const payload = await response.json()

    if (payload.data) {
        dispatch({ type: LOGIN, payload })
        dispatch(updateField('otpUaFlow', true))
        document.cookie = "cbm_login=true; max-age=604800";
    }

    if (!payload.data) {
        dispatch(updateField('otpValid', false))
    }

    return { payload, response }
})

export const signInOtp = () => middleware(async (dispatch, getState) => {
    const { app: { lang, otpPhoneNumber, otpIsResend } } = getState()
    const phone = encodeURIComponent(otpPhoneNumber)
    const response = await fetch(`${API_BASE_URL}/users/sign_in_otp_v2?is_resend=${otpIsResend}&phone=${phone}&device_id=${phone}`,{
        method: 'post',
        headers: {
            'Accept-Language': lang,
        },
    })
    const payload = await response.json()

    if (payload.data?.token) {
        dispatch(updateField('otpToken', payload.data.token))
        dispatch(updateField('otpStageName', 'otp_sms'))
    }

    return { payload, response }
})

export const addPatientSendData = stages => middleware(async (dispatch, getState) => {
    const {app: { token, lang = 'en', doctorData: { country } }, addPatient: { phone_number, messenger, type_treatment, diagnosis } } = getState()

    const createPatientResponse = await fetch(`${API_BASE_URL}/patients/by_doctor?token=${token}`, {
        method: 'post',
        headers: {
            'Accept-Language': lang,
        },
        body: JSON.stringify({phone_number, messenger})
    })

    const createPatientPayload = await createPatientResponse.json()
    if (!createPatientPayload.data)  return { createPatientPayload, createPatientResponse }

    const patient_id = createPatientPayload.data.id
    const patientProgramResponse = await fetch(`${API_BASE_URL}/programs?token=${token}`, {
        method: 'post',
        headers: {
            'Accept-Language': lang,
            'x-country': country,
        },
        body: JSON.stringify({ patient_id, type_treatment, stages })
    })

    const patientProgramPayload = await patientProgramResponse.json()
    if (!patientProgramPayload.data) return { patientProgramPayload, patientProgramResponse }

    const patientUpdateResponse = await fetch(`${API_BASE_URL}/patients/${patient_id}?token=${token}`, {
        method: 'put',
        headers: {
            'Accept-Language': lang,
        },
        body: JSON.stringify({ diagnosis })
    })
    const patientUpdatePayload = await patientUpdateResponse.json()
    if (patientUpdatePayload.data) {
        dispatch(addPatientUpdateField({ key: 'dataSent', value: true }))
        dispatch(getPatients())
    }

    return { patientUpdatePayload, patientUpdateResponse }
})

export const addPatientOneMore = { type: ADD_PATIENT_ONE_MORE }

export const addPatientInitialState = { type: ADD_PATIENT_INITIAL_STATE }

export const addPatientIncreaseWeeks = payload => ({ type: ADD_PATIENT_INCREASE_WEEKS, payload })

export const addPatientDecreaseWeeks = payload => ({ type: ADD_PATIENT_DECREASE_WEEKS, payload })

export const addPatientUpdateField = payload => ({ type: ADD_PATIENT_UPDATE_FIELD, payload })

export const addPatientNextStep = { type: ADD_PATIENT_NEXT_STEP }

export const addPatientPrevStep = { type: ADD_PATIENT_PREV_STEP }

export const addPatientHideComponent = { type: ADD_PATIENT_HIDE_COMPONENT }

export const addPatientShowComponent = { type: ADD_PATIENT_SHOW_COMPONENT }

export const pushDayData = payload => ({ type: PUSH_DAY_DATA, payload })

export const getDaysData = (patientId) => middleware(async (dispatch, getState) => {
    const {app: { token, lang = 'en' }} = getState()
    const response = await fetch(`${API_BASE_URL}/patients/${patientId}/steps_last_day?token=${token}`, {
        method: 'get',
        headers: {
            'Accept-Language': lang,
        },
    })

    const payload = await response.json()

    if (payload.data) {
        dispatch({ type: GET_DAYS_DATA, payload })
    }

    return { payload, response }
})

export const creationAccountSubmit = ({userData, doctorData}) => middleware(async (dispatch, getState) => {
    const {app: {token, doctorId, lang}} = getState()
    let response
    let payload
    const userResponse = await fetch(`${API_BASE_URL}/users/${doctorId}?token=${token}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': lang,
        },
        body: JSON.stringify(userData),
    })
    const doctorResponse = await fetch(`${API_BASE_URL}/doctors/${doctorId}?token=${token}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': lang,
        },
        body: JSON.stringify(doctorData),
    })

    const userPayload = await userResponse.json()
    const doctorPayload = await doctorResponse.json()

    if (userPayload.data && doctorPayload.data) {
        dispatch(updateField('contentPopup', 'registration_successful'))
        setTimeout(() => {
            dispatch({ type: UPDATE_DOCTOR, payload: doctorPayload })
            dispatch(updateField('contentPopup', ''))
        }, 2000)
    }

    if (!doctorPayload.data) {
        payload = doctorPayload
        response = doctorResponse
    } else {
        payload = userPayload
        response = userResponse
    }

    return { payload, response }
})

export const updateCreationAccountField = payload => ({ type: UPDATE_CREATION_ACCOUNT_FIELD, payload })

export const changeCurrentLoad = (patientId, load) => middleware(async (dispatch, getState) => {
    const {app: { token, lang }} = getState()
    const response = await fetch(`${API_BASE_URL}/patients/${patientId}/change_current_load?load=${load}&token=${token}`, {
        method: 'post',
        headers: {
            'Accept-Language': lang,
        },
    })

    const payload = await response.json()
    if (payload.data && payload.data) {
        //await dispatch(getPatients())
        await dispatch(updatePatientData(patientId))
        dispatch(closePopup())
    } else {
        dispatch(closePopup())
    }

    return { payload, response }
})

export const closePopup = () => ({ type: CLOSE_POPUP })

export const updateStepIndex = payload => ({ type: UPDATE_STEP_INDEX, payload })

export const changeControlType = payload => ({ type: CHANGE_CONTROL_TYPE, payload })

export const createAccountSendEmail = callback => middleware(async (dispatch, getState) => {
    const {app: { email, lang }} = getState()
    const response = await fetch(`${API_BASE_URL}/login/send-email?email=${encodeURIComponent(email)}`, {
        method: 'post',
        headers: {
            'Accept-Language': lang,
        },
    })
    const payload = await response.json()

    payload.data && callback()

    return { payload, response }
})

export const createAccount = (loginData, language) => async dispatch => {
    try {
        dispatch(requestStart())
        const response = await fetch(`${API_BASE_URL}/doctors`, {
            method: 'post',
            headers: {
                'Accept-Language': language,
            },
            body: JSON.stringify(loginData)
        })
        const payload = await response.json()

        if (payload.data) {
            dispatch(requestEnd())
            return true
        } else if (payload.detail) {
            dispatch({ type: SHOW_NOTIFICATION, payload: { actionText: payload.detail, type: 'error' } })
        } else {
            dispatch({ type: SHOW_NOTIFICATION, payload: { actionText: response.status + ': ' + response.statusText, type: 'error' } })
        }
        dispatch(requestEnd())
    } catch (e) {
        dispatch({ type: SHOW_NOTIFICATION, payload: { actionText: e.message, type: 'error' } })
        dispatch(requestEnd())
    }
}

export const removeNotification = (token, notificationId, language) => async () => {
    return fetch(`${API_BASE_URL}/notifications/${notificationId}?token=${token}`, {
        method: 'delete',
        headers: {
            'Accept-Language': language,
        },
    })
}

export const setNewEmail = (token, doctorId, language, email) => async () => {
    return fetch(`${API_BASE_URL}/users/${doctorId}/change_email?token=${token}`, {
        method: 'post',
        headers: {
            'Accept-Language': language,
        },
        body: `{"email": "${email}"}`
    })
}

export const removeAccount = (token, doctorId, language) => middleware(async (dispatch) => {
    const response = await fetch(`${API_BASE_URL}/doctors/${doctorId}?token=${token}`, {
        method: 'delete',
        headers: {
            'Accept-Language': language,
        },
    })
    const payload = await response.json()

    if (payload.message === 'Doctor deleted') {
        dispatch({ type: SHOW_NOTIFICATION, payload: { actionText: payload.message } })
        dispatch(setPopup(''))
        setTimeout(() => {
            logout();
            window.location = '/sign-up';
        }, 2000)
    }

    return { payload, response }
})

export const saveNewPassword = (token, doctorId, password) => async () => {
    return fetch(`${API_BASE_URL}/users/${doctorId}/new_password?new_password=${password}&token=${token}`, { method: 'post', })
}

export const updateDoctorData = middleware(async (dispatch, getState) => {
    const { app: { doctorId, token } } = getState()
    const response = await fetch(`${API_BASE_URL}/doctors/${doctorId}?token=${token}`)
    const payload = await response.json()
    if (payload.data) {
        dispatch({ type: UPDATE_DOCTOR_DATA, payload })
    }

    return { payload, response }
})


export const getNotifications = (token, doctorId) => middleware(async (dispatch, getState) => {
    const { app: { lang } } = getState()
    const response = await fetch(`${API_BASE_URL}/doctors/${doctorId}/notifications?offset=0&limit=100&token=${token}`,{
        method: 'get',
        headers: {
            'Accept-Language': lang,
        },
    })
    const payload = await response.json()

    if (payload.data) {
        dispatch({ type: GET_NOTIFICATIONS, payload })
    }

    return { payload, response }
})

export const getConsultations = doctorId => middleware(async (dispatch, getState) => {
    const { app: { lang, token } } = getState()
    const response = await fetch(`${API_BASE_URL}/doctors/${doctorId}/consultations?offset=0&limit=100&token=${token}`,{
        method: 'get',
        headers: {
            'Accept-Language': lang,
        },
    })
    const payload = await response.json()
    if (payload.data) {
        dispatch({ type: GET_CONSULTATIONS, payload })
    }

    return { payload, response }
})

export const setScrollOffset = offset => {
    return { type: SET_SCROLL_OFFSET, payload: offset }
}

export const updateField = (key, value) => {
    return { type: UPDATE_FIELD, payload: { key, value } }
}

export const updateSettingsField = (key, value) => {
    return { type: UPDATE_SETTINGS_FIELD, payload: { key, value } }
}

export const updatePatientField = (key, value) => {
    return { type: UPDATE_PATIENT_FIELD, payload: { key, value } }
}

export const setInitialPatientState = () => {
    return { type: SET_INITIAL_PATIENT_STATE }
}

export const setContentPopup = text => {
    return { type: SET_CONTENT_POPUP, payload: text }
}

export const setPopup = text => {
    return { type: SET_POPUP, payload: text }
}

export const updatePhotoError = text => {
    return { type: UPDATE_PHOTO_ERROR, payload: text }
}

export const updateContacts = (token, id, data) => async dispatch => {
    if (!updateContacts.requestInProgress) {
        updateContacts.requestInProgress = true
        const response = await fetch(`${API_BASE_URL}/users/${id}?token=${token}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        const json = await response.json()
        dispatch({ type: UPDATE_CONTACTS, payload: json })
        updateContacts.requestInProgress = false
    }
}

export const removePhoto = (token, id, data) => async dispatch => {
    if (!removePhoto.requestInProgress) {
        removePhoto.requestInProgress = true
        const response = await fetch(`${API_BASE_URL}/users/${id}?token=${token}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        const json = await response.json()
        dispatch({ type: REMOVE_PHOTO, payload: json })
        removePhoto.requestInProgress = false
    }
}

export const uploadPhoto = (token, userId, data) => middleware(async (dispatch, getState) => {
    const {app: {token, doctorId}} = getState()
    const response = await fetch(`${API_BASE_URL}/users/${doctorId}/add_photo?token=${token}`, {
        method: 'post',
        headers: {
            'accept': 'application/json',
        },
        body: data
    })
    const payload = await response.json()
    if (payload.data) {
        dispatch({ type: UPLOAD_PHOTO, payload })
    }

    return { payload, response }
})

export const showFieldsErrors = () => {
    return { type: SHOW_FIELDS_ERRORS }
}

export const unregisterFieldValidation = id => {
    return { type: UNREGISTER_FIELD_VALIDATION, payload: id }
}

export const updateFieldValidation = data => {
    return { type: UPDATE_FIELD_VALIDATION, payload: data }
}

export const getHospitals = (token, language) => async dispatch => {
    try {
        const response = await fetch(`${API_BASE_URL}/hospitals?token=${token}&lang=${language}`)
        const json = await response.json()
        dispatch({ type: GET_HOSPITALS, payload: json })
    } catch (e) {
        dispatch({ type: SHOW_NOTIFICATION, payload: { actionText: 'Something Went Wrong' } })
    }
}

export const getHospitalsGoogle = (search, city, key) => middleware(async (dispatch, getState) => {
    let {app: {token, lang, doctorData}, creationAccount: {country = 'ua'}} = getState()
    country = country === 'disabled' ? (doctorData.country ? doctorData.country : 'ua') : country
    const response = await fetch(`${API_BASE_URL}/hospitals/google?search=${encodeURIComponent(search)}&lang=${lang}&country=${country}&city=${encodeURIComponent(city)}&token=${token}`, {
        method: 'get',
        headers: {
            'Accept-Language': lang,
        },
    })
    const payload = await response.json()
    if (payload.data) {
        dispatch({ type: GET_HOSPITALS_GOOGLE, payload: { data: payload.data, key, search }})
    }

    return { payload, response }
})

export const updateJobsFieldValue = (i, key, value) => {
    return { type: UPDATE_JOBS_FIELD_VALUE, payload: { i, key, value } }
}

export const updateCreationAccountJobsFieldValue = (i, key, value) => {
    return { type: UPDATE_CREATION_ACCOUNT_JOBS_FIELD_VALUE, payload: { i, key, value } }
}

export const addWorkplace = (id, language) => {
    return { type: ADD_WORKPLACE, payload: { id, language } }
}

export const creationAccountAddWorkplace = (id, language) => {
    return { type: CREATION_ACCOUNT_ADD_WORKPLACE, payload: { id, language } }
}

export const deleteWorkplace = i => {
    return { type: DELETE_WORKPLACE, payload: i }
}

export const creationAccountDeleteWorkplace = i => {
    return { type: CREATION_ACCOUNT_DELETE_WORKPLACE, payload: i }
}

export const restoreState = sessionData => {
    return { type: RESTORE_STORE, payload: sessionData }
}

export const signIn = (loginData, notification) => middleware(async (dispatch, getState) => {
    const {app: { lang }} = getState()
    const response = await fetch(`${API_BASE_URL}/doctors/login/access-token`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': lang
        },
        body: new URLSearchParams(loginData).toString()
    })

    const payload = await response.json()
    if (payload.data) {
        dispatch({ type: LOGIN, payload })
        document.cookie = "cbm_login=true; max-age=604800";
    }

    return { payload, response}
})

export const logout = () => middleware(async (dispatch, getState) => {
    const {app: {token}} = getState()
    const response = await fetch(`${API_BASE_URL}/logout?token=${token}`, {
        method: 'post',
    })
    const payload = await response.json()
    if (payload.data) {
        localStorage.removeItem('cbm')
        dispatch({ type: LOGOUT, payload })
    }

    return { payload, response }
})

export const getPatients = () => middleware(async (dispatch, getState) => {
    const {app: {token, doctorId}} = getState()
    const response = await fetch(`${API_BASE_URL}/doctors/${doctorId}/patients?token=${token}`)
    const payload = await response.json()
    if (payload.data) {
        dispatch({ type: GET_PATIENTS, payload })
    } else if (payload.detail) {
        const payload = { key: 'loaded', value: true}
        dispatch({ type: UPDATE_MY_PATIENTS_FIELD, payload })
    }

    return { payload, response }
})

export const updatePatientData = patientId => middleware(async (dispatch, getState) => {
    const {app: {token}} = getState()
    let response = await fetch(`${API_BASE_URL}/patients/${patientId}?token=${token}`)
    //let response = await fetch(`${API_BASE_URL}/users/${patientId}?token=${token}`)
    let payload = await response.json()

    const response2 = await fetch(`${API_BASE_URL}/patients/${patientId}/analytics?token=${token}`)
    const payload2 = await response2.json()

    if (payload.data) {
        //payload.data = payload2.data ? { ...payload.data,  ...payload2.data.program } : payload.data
        if (payload2.data) {
            payload.data = { ...payload.data, ...payload2.data.program, current_program: payload2.data.program }
        }
        //payload.data = payload2.data ? { ...payload.data,  ...payload2.data.program } : payload.data
        dispatch({ type: UPDATE_PATIENT_DATA, payload })
        dispatch(updatePatientField('loaded', true))
    }

    return { payload, response }
})

export const filteredPatients = (patientsFiltered, statusesEnabled) => {
    return { type: FILTERED_PATIENTS, payload: { patientsFiltered, statusesEnabled } }
}

export const patientsSearchValue = value => {
    return { type: PATIENTS_SEARCH_VALUE, payload: value }
}

export const filteredBySearchPatients = (fullNameFiltered, diagnosisFiltered) => {
    return { type: FILTERED_BY_SEARCH_PATIENTS, payload: { fullNameFiltered, diagnosisFiltered } }
}

export const deletePatient = (token, id) => async dispatch => {
    // const response = await fetch (`${API_BASE_URL}/patients/${id}?token=${token}`, {
    //     method: 'delete',
    // })
    // const json = await response.json()
    dispatch({ type: DELETE_PATIENT, payload: id })
}

export const showNotification = (targetText, actionText, type) => {
    return { type: SHOW_NOTIFICATION, payload: { targetText, actionText, type } }
}

export const hideNotification = () => {
    return { type: HIDE_NOTIFICATION }
}

export const setWindowWidth = width => {
    return { type: SET_WINDOW_WIDTH, payload: width }
}

export const toggleMenuStatus = () => {
    return { type: TOGGLE_MENU_STATUS }
}

export const setSettingTabData = (key, data) => {
    return { type: SET_SETTING_TAB_DATA, payload: { key, data } }
}

export const setSettingData = (tabKey, key, value) => {
    return { type: SET_SETTING_DATA, payload: { tabKey, key, value } }
}

export const setActiveTab = id => {
    return { type: SET_ACTIVE_TAB, payload: id }
}

export const getCites = (token, language) => async dispatch => {
    const response = await fetch(`${API_BASE_URL}/cities?token=${token}&lang=${language}`)
    const json = await response.json()
    dispatch({ type: GET_CITES, payload: json })
}

export const getCitesGoogle = (key, search) => middleware(async (dispatch, getState) => {
    let {app: {token, lang, doctorData}, creationAccount: {country = 'ua'}} = getState()
    country = country === 'disabled' ? (doctorData.country ? doctorData.country : 'ua') : country
    const response = await fetch(`${API_BASE_URL}/cities/google?search=${encodeURIComponent(search)}&lang=${lang}&country=${country}&token=${token}`, {
        method: 'get',
        headers: {
            'Accept-Language': lang,
        },
    })
    const payload = await response.json()

    if (payload.data) {
        dispatch({ type: GET_CITES_GOOGLE, payload: { data: payload.data, key, search }})
    }

    return { payload, response }
})

export const updateDoctor = data => middleware(async (dispatch, getState) => {
    const {app: {token, doctorId, lang}} = getState()
    const response = await fetch(`${API_BASE_URL}/doctors/${doctorId}?token=${token}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': lang,
        },
        body: JSON.stringify(data),
    })
    const payload = await response.json()
    if (payload.data) {
        dispatch({ type: UPDATE_DOCTOR, payload })
    }

    return { payload, response }
})