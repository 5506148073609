import {LOGOUT, REQUEST_END, REQUEST_START, SHOW_NOTIFICATION} from "../store/types";
import {noInternet} from "../translations";

const requestStart = () => ({ type: REQUEST_START })

const requestEnd = () => ({ type: REQUEST_END })

const thunkCallback = async (callbackFnForThunk, dispatch, getState) => {
    try {
        dispatch(requestStart())
        const callbackBounded = callbackFnForThunk.bind(callbackFnForThunk, dispatch, getState)
        const callbackPayload = await callbackBounded()
        const payload = (callbackPayload && callbackPayload.payload) || {}
        const response = callbackPayload && callbackPayload.response

        if (payload.detail && (
            payload.detail === 'Invalid authentication credentials' ||
            payload.detail === 'Unfortunately, this doctor is not registered')
        ) {
            localStorage.removeItem('cbm')
            dispatch({ type: LOGOUT })
        } else if (payload.detail) {
            dispatch({ type: SHOW_NOTIFICATION, payload: { actionText: payload.detail, type: 'error' } })
        } else if (!payload.data && !payload.detail && response && payload.message !== 'Doctor deleted') {
            dispatch({ type: SHOW_NOTIFICATION, payload: { actionText: response.status + ': ' + response.statusText, type: 'error' } })
        }
        dispatch(requestEnd())
    } catch (e) {
        const { app: { lang } } = getState()
        const message = window.navigator.onLine ? e.message : noInternet[lang]
        dispatch({ type: SHOW_NOTIFICATION, payload: { actionText: message, type: 'error' } })
        dispatch(requestEnd())
        console.error(e)
    }
}
export default callbackFnForThunk => async (d, s) => thunkCallback.call(null, callbackFnForThunk, d, s)
