import React, {useEffect} from 'react'
import {useDispatch} from "react-redux"
import {updateFieldValidation, unregisterFieldValidation} from "../../store/actions"
import './ValidateField.css'

let fieldValidationFn = updateFieldValidation
let unregisterFieldValidationFn = unregisterFieldValidation

export const setFieldValidationFn = fn => fieldValidationFn = fn
export const setUnregisterFieldValidationFn = fn => unregisterFieldValidationFn = fn

export default props => {
    const dispatch = useDispatch()
    const id = props.id
    const showError = props.showError === undefined ? true : props.showError
    const validation = props.validation
    const isEmptyAllowed = props.emptyAllowed === true
    const error = isEmptyAllowed ? false : validation.find(item => item[0] === true)
    const [isValid, errorMessage] = error ? [false, error[1]] : [true, '']

    useEffect(() => {
        dispatch(fieldValidationFn({id, valid: isValid, showError}))
        return () => dispatch(unregisterFieldValidationFn(id))
    }, [])
    useEffect(() => {
        dispatch(fieldValidationFn({id, valid: isValid}))
    }, [isValid])

    const fieldWrapperClassName = !isValid && showError ? 'field-wrapper field-wrapper--error' : 'field-wrapper'
    const errorBlock = !isValid && showError ? <div className="field-error-text">{errorMessage}</div> : null

    return (
        <>
            <div className={fieldWrapperClassName}>
                <div className="field-inner-wrapper">
                    {props.children}
                </div>
                {errorBlock}
            </div>
        </>
    )
}