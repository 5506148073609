import {useDispatch, useSelector} from "react-redux"
import {removeAccount, setActiveTab, setContentPopup, setPopup} from "../../store/actions";
import Popup from "../Popup/Popup";
import waveEffect from "../../helpers/waveEffect";
import './DeleteAccount.css'
import emoji14src from "../../images/emoji-14-icon.png"
import {ReactComponent as Emoji15Icon} from "../../images/emoji-15-icon.svg";
import {deleteAccount as translations} from "../../translations";
import Button from "../Button/Button";


export default props => {
    const dispatch = useDispatch()
    const language = useSelector(state => state.app.lang)
    const token = useSelector(state => state.app.token)
    const doctorId = useSelector(state => state.app.doctorId)
    const popup = useSelector(state => state.settings.popup)
    const patients = useSelector(state => state.myPatients.patients.all)
    const texts = translations[language]
    let popupContent = null

    const confirmDeleteAccountHandler = () => {
        dispatch(removeAccount(token, doctorId, language))
    }

    switch (popup) {
        case 'remove_account':
            popupContent = (
                <Popup className="popup-remove-account">
                    {/*<Emoji14Icon />*/}
                    <img src={emoji14src} alt="Emoji"/>
                    <div className="popup-title">{texts.deleteAccount}</div>
                    <div className="popup-text">{texts.deleteAccountText}</div>
                    <div className="remove-account-buttons-wrapper">
                        <Button onClick={() => dispatch(setPopup(''))} fullWidth={true}>{texts.cancel}</Button>
                        <div className="gap-12" />
                        <Button onClick={confirmDeleteAccountHandler} type="outline" fullWidth={true}>{texts.confirmDeleteButton}</Button>
                    </div>
                </Popup>
            )
            break
        case 'prevent_remove_account':
            popupContent = (
                <Popup>
                    <Emoji15Icon />
                    <div className="popup-title">{texts.deleteAccount}</div>
                    <div className="popup-text">{texts.preventDeleteText}</div>
                    <button className="tab-button tab-button--save remove-account" onClick={()=>dispatch(setPopup(''))} >
                        {texts.ok}
                    </button>
                </Popup>
            )
            break
    }

    const deleteAccountHandler = e => {
        waveEffect(e)
        const isSetOpenPatients = (patients.length && patients.find(patient => patient.status !== 'canceled')) || false
        if (isSetOpenPatients) {
            dispatch(setPopup('prevent_remove_account'))
        } else {
            dispatch(setPopup('remove_account'))
        }
    }

    return (
        <>
            <div className="tab-section">
                <button className="tab-button tab-button--delete" onClick={deleteAccountHandler} >
                    {texts.deleteAccount}
                </button>
            </div>
            {popupContent}
        </>
    )
}