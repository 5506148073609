import React from "react";
import {ReactComponent as TimeIcon} from "../../images/time-icon.svg";
import './ChartSmall.css'
import {useSelector} from "react-redux";
import {Line} from 'react-chartjs-2'
import {chartSmall as translations} from "../../translations";
import _ from "../../helpers/_";
import isset from "../../helpers/isset";


const options = {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
        display: false
    },
    scales : {
        yAxes : [{
            ticks : {
                display: false,
                min : 0,
                max: 100,
            },
            gridLines: {
                display: false,
            },
            afterFit: function(scaleInstance) {
                scaleInstance.width = 0;
                scaleInstance.height = 0;
            }
        }],
        xAxes : [{
            ticks : {
                display: false,
            },
            gridLines: {
                display: false,
            },
            afterFit: function(scaleInstance) {
                scaleInstance.width = 0;
                scaleInstance.height = 0;
            }
        }],
    },
}


const widthData = [618, 618, 305, 200]
const backgroundColorData = {
    over: '#F0F0F0',
    current: '#EFF4F1',
    next: 'rgba(33, 76, 79, 0.1)'
}
const lineBackgroundColorData = {
    over: '#C9C9C9',
    current: '#94B4A4',
    next: '#214C4F'
}

export default ({payload}) => {
    const language = useSelector(state => state.app.lang)
    const texts = translations[language]
    const status = payload.status
    const patientCurrentDay = payload.patientCurrentDay
    const patientCurrentLoad = payload.patientCurrentLoad
    const currentPressureOffset = payload.currentPressureOffset
    const iteration = payload.iteration
    const patientStagesLength = payload.patientStagesLength
    const lastDay = payload.lastDay
    const totalDays = payload.totalDays
    const width = widthData[patientStagesLength]

    const labels = payload.data.length > 2
        ? Array(payload.data.length).fill('')
        : []

    /*
    * Prepare chart data
    * */
    const data = {
        labels,
        datasets: [
            {
                fill: 'start',
                backgroundColor: lineBackgroundColorData[status],
                borderColor: lineBackgroundColorData[status],
                borderWidth: 0,
                pointRadius: payload.data.length > 2 ? 3 : 0,
                pointBackgroundColor: '#9A975A',
                data: payload.data,
                lineTension: 0,
            }
        ]
    }
    const plugins = [{
        id: 'custom_canvas_background_color',
        beforeDraw: (chart) => {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = backgroundColorData[status];
            ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
            ctx.restore();
        }
    }]

    const offsetLeft = iteration === 0 && (patientCurrentDay === 1 || patientCurrentDay === 2)
    const offsetRight = patientCurrentDay === totalDays || patientCurrentDay + 1 === totalDays || patientCurrentDay + 2 === totalDays
    let currentPressureTextOffset = currentPressureOffset
    offsetLeft && (currentPressureTextOffset = 10)
    offsetRight && (currentPressureTextOffset = 85)

    /*
    * Components
    * */
    const stageEndComponent = status === 'over' ? <div className="chart-small-step-over">{texts.stageOver}</div> : null
    const stageCurrentComponent = _(status === 'current' && isset(currentPressureOffset),
        <>
            <div className="chart-small-step-current" style={{left: currentPressureOffset + '%'}} />
            <div className="chart-small-step-current-text" style={{left: currentPressureTextOffset + '%'}}>
                {texts.currentComponent(patientCurrentDay, patientCurrentLoad)}
            </div>
        </>
            )

    return (
        <>
            <div className={`chart-small-wrapper chart-small-wrapper--${status}`}>
                {stageEndComponent}
                {stageCurrentComponent}
                <div className="chart-small-canvas-wrapper" >
                    <Line width={width} height={75} data={data} options={options} plugins={plugins} />
                </div>
                <div className="chart-small-weeks-wrapper">
                    <TimeIcon /> {payload.weeks}
                </div>
            </div>
        </>
    )
}