import React from "react";
import {updateField} from "../../store/actions";
import {useDispatch} from "react-redux";
import getLanguage from "../../helpers/getLanguage";

export default () => {
    const dispatch = useDispatch()
    const language = getLanguage()

    const languageChangeHandler = e => {
        localStorage.setItem('cbm_language', e.target.value)
        dispatch(updateField('lang', e.target.value))
    }

    return (
        <div className="language-wrapper">
            <select name="language" id="language" value={language} onChange={languageChangeHandler}>
                <option value="ru">Русский</option>
                <option value="en">English</option>
                <option value="uk">Український</option>
            </select>
        </div>
    )
}