const availableLanguagesKeys = ['en', 'ru', 'uk']

export default () => {
    let language = localStorage.getItem('cbm_language')
    if (!language) {
        language = navigator.language.slice(0, 2)
        if (!availableLanguagesKeys.includes(language)) {
            language = 'en'
        }
        localStorage.setItem('cbm_language', language)
    }
    return language
}