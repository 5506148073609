import {useSelector, useDispatch} from "react-redux"
import {setActiveTab} from "../../store/actions"
import './Tab.css'
import {ReactComponent as ChevronIcon} from '../../images/chevron-icon.svg'
import _ from "../../helpers/_";

export default props => {
    const dispatch = useDispatch()
    const activeTabId = useSelector(state => state.settings.activeTabId)

    const tabOnclick = () => props.id === activeTabId
        ? dispatch(setActiveTab(null))
        : dispatch(setActiveTab(props.id))
    const isTabActive = props.id === activeTabId
    const tabWrapperClassName = `tab-wrapper ${isTabActive ? 'tab-wrapper--active' : ''}`

    return (
        <div className={tabWrapperClassName} >
            <header className="tab-header" onClick={tabOnclick}>
                <div className="tab-title">{props.title}</div>
                <ChevronIcon />
            </header>

            {_(isTabActive,
            <div className="tab-content-wrapper">
                {props.children}
            </div>)}
        </div>
    )
}