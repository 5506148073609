import {LOGOUT, RESTORE_STORE, SHOW_FIELDS_ERRORS, UNREGISTER_FIELD_VALIDATION, UPDATE_FIELD_VALIDATION} from "./types";

const initialState = {
    validation: {},
}

export const validationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SHOW_FIELDS_ERRORS: {
            const validation = {}
            Object.keys(state.validation).forEach(key => validation[key] = {
                ...state.validation[key],
                showError: true
            })

            return { ...state, validation }
        }
        case UNREGISTER_FIELD_VALIDATION: {
            const id = action.payload
            const validation = { ...state.validation }
            delete validation[id]

            return { ...state, validation }
        }
        case UPDATE_FIELD_VALIDATION: {
            const id = action.payload.id
            const valid = action.payload.valid === undefined ? state.validation[id].valid : action.payload.valid
            const showError = action.payload.showError === undefined
                ? state.validation[id] && state.validation[id].showError
                    ? state.validation[id].showError
                    : false
                : action.payload.showError

            return { ...state,
                validation: { ...state.validation,
                    [id]: { valid, showError },
                }
            }
        }
        case RESTORE_STORE: return action.payload.validation
        case LOGOUT: return initialState
        default: return state
    }
}