import {ReactComponent as SupportIcon} from "../../images/support-icon.svg";
import {NavLink} from "react-router-dom";
import React from "react";
import {supportLink as translations} from "../../translations";
import {useSelector} from "react-redux";
import './SupportLink.css'

export default () => {
    const lang = useSelector(state => state.app.lang)
    const texts = translations[lang]

    return <NavLink to="/support" className="support-link-component" target="_blank">
        <SupportIcon />
        {texts.help}
    </NavLink>
}