import {useDispatch, useSelector} from "react-redux"
import {withRouter} from "react-router-dom"
import {filteredBySearchPatients, patientsSearchValue} from "../../store/actions"
import DropDown from "../DropDown/DropDown"
import '../../styles/search.css'
import './PatientsSearch.css'
import {ReactComponent as SearchIcon} from "../../images/search-icon.svg"
import {ReactComponent as PatientPhotoEmpty} from "../../images/patient-photo-empty.svg"
import {ReactComponent as SearchDiagnosisIcon} from "../../images/search-diagnosis-icon.svg"
import {ReactComponent as CloseIcon} from "../../images/patients-filter-close-icon.svg"
import React, {useEffect} from "react";
import {patientsSearch as translations} from "../../translations";


export default withRouter(props => {
    const dispatch = useDispatch()
    const { fullNameFiltered, diagnosisFiltered } = useSelector(state => state.myPatients.patients.filteredBySearch)
    const patients = useSelector(state => state.myPatients.patients.all)
    const language = useSelector(state => state.app.lang)
    const searchValue = useSelector(state => state.myPatients.search)
    const addClassName = props.addClassName
    const className = addClassName ? 'search-input-wrapper ' + addClassName : 'search-input-wrapper'
    const texts = translations[language]

    useEffect(()=> {
        if (searchValue) {
            dispatch(patientsSearchValue(''))
        }
        if (fullNameFiltered.length || diagnosisFiltered.length) {
            dispatch(filteredBySearchPatients([], []))
        }
    }, [])

    const searchHandler = ({target: {value}}) => {
        dispatch(patientsSearchValue(value))
        if (value.trim().length >= 3) {
            const filteredByFullName = patients.filter(({ last_name, first_name, phone_number }) => {
                if (last_name && first_name) {
                    return last_name.toLowerCase().includes(value.toLowerCase()) ||
                        first_name.toLowerCase().includes(value.toLowerCase())
                }
                if (phone_number) {
                    return phone_number.includes(value)
                }
                return false
            })
            const filteredByDiagnosis = patients.filter(({ diagnosis }) => {
                if (diagnosis) {
                    return diagnosis.toLowerCase().includes(value.toLowerCase())
                }
                return false
            })

            dispatch(filteredBySearchPatients(filteredByFullName, filteredByDiagnosis))
        } else if (fullNameFiltered.length || diagnosisFiltered.length) {
            dispatch(filteredBySearchPatients([], []))
        }
    }
    const searchItemClick = id => () => {
        dispatch(patientsSearchValue(''))
        if (fullNameFiltered.length || diagnosisFiltered.length) {
            dispatch(filteredBySearchPatients([], []))
        }
        props.history.push('/patients/' + id)
    }
    const clearSearchField = e => {
        e.stopPropagation()
        dispatch(patientsSearchValue(''))
        if (fullNameFiltered.length || diagnosisFiltered.length) {
            dispatch(filteredBySearchPatients([], []))
        }
    }

    const fullNameFilteredContainer = fullNameFiltered.length ?
        <div className="search-result-wrapper">
            <div className="search-result-title">{texts.patients}</div>
            {fullNameFiltered.map(({id, first_name, last_name, photo, phone_number}) => {
                const patientPhoto = photo ? <img src={photo} /> : <PatientPhotoEmpty />
                const fullName = first_name && last_name
                    ? first_name + ' ' + last_name
                    : phone_number
                return (
                    <div className="search-result-item" onClick={searchItemClick(id)} key={`name${id}`}>
                        {patientPhoto}
                        {fullName}
                    </div>
                )
            })}
        </div> : null

    const diagnosisFilteredContainer = diagnosisFiltered.length ?
        <div className="search-result-wrapper">
            <div className="search-result-title">{texts.diagnosis}</div>
            {diagnosisFiltered.map(({id, first_name, last_name, diagnosis, phone_number}) => {
                const fullName = first_name && last_name
                    ? first_name + ' ' + last_name
                    : phone_number
                return <div className="search-result-item search-result-item-diagnosis" onClick={searchItemClick(id)} key={`diagnosis${id}`}>
                    <SearchDiagnosisIcon/>
                    <div>
                        <div className="search-result-small">{fullName}</div>
                        {diagnosis}
                    </div>
                </div>
            })}
        </div> : null

    const closeIcon = (searchValue.length && <CloseIcon onClick={clearSearchField}/>) || null
    const nothingFound = searchValue.length > 2 && !fullNameFilteredContainer && !diagnosisFilteredContainer && texts.nothingFound
    const dropDownCondition = fullNameFilteredContainer || diagnosisFilteredContainer || searchValue.length > 2

    return (
        <DropDown className="patients-search" condition={dropDownCondition}>
            <DropDown.Element>
                <label htmlFor="searchInput" className={className}>
                    <SearchIcon />
                    <input type="text" id="searchInput" autoComplete="off"
                           onChange={searchHandler} className="search-input"
                           placeholder={translations[language].searchPlaceholder}
                           value={searchValue} />
                    {closeIcon}
                </label>
            </DropDown.Element>
            <DropDown.List>
                <div className="search-result-container-wrapper">
                    <div className="search-result-container">
                        {fullNameFilteredContainer}
                        {diagnosisFilteredContainer}
                        {nothingFound}
                    </div>
                </div>
            </DropDown.List>
        </DropDown>
    )
})