export default e => {
    if (!e.currentTarget) return
    const element = e.currentTarget
    const { left, top } = element.getBoundingClientRect()
    const diameter = Math.max(element.clientWidth, element.clientHeight)
    const radius = diameter / 2
    const spanPositionLeft = e.clientX - left - radius
    const spanPositionTop = e.clientY - top - radius

    const span = document.createElement('span')
    span.style.width = span.style.height = `${diameter}px`
    span.className = 'wave-effect'
    span.style.left = spanPositionLeft + 'px'
    span.style.top = spanPositionTop + 'px'

    try {
        element.appendChild(span)
    } catch (e) {
        console.log(e)
    }

    (function () {
        const parent = e.currentTarget
        const thisSpan = span
        setTimeout(() => {
            try {
                parent.removeChild(thisSpan)
            } catch (e) {
                console.log(e)
            }
        }, 600)
    })()
}