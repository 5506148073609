import React from "react"
import {useSelector} from "react-redux"
import '../../components/InitialScreenWrapper/InitialScreenWrapper.css'
import './SignInPage.css'
import _ from "../../helpers/_";
import Notification from "../../components/Notification/Notification";
import {ReactComponent as LogoIcon} from "../../images/logo-initial-screen-icon.svg";
import InitialScreenRightSide from "../../components/InitialScreenRightSide/InitialScreenRightSide";
import SupportLink from "../../components/SupportLink/SupportLink";
import OtpEnterNumber from "../../components/OtpEnterNumber/OtpEnterNumber";
import OtpSms from "../../components/OtpSms/OtpSms";
import SelectLanguage from "../../components/SelectLanguage/SelectLanguage";

export default () => {
    const notification = useSelector(state => state.app.notification)
    const otpStageName = useSelector(state => state.app.otpStageName)
    const windowWidth = useSelector(state => state.app.windowWidth)
    const isShowNotification = notification.show
    const notificationComponent = _(isShowNotification, <Notification />)

    const enterPhoneContent = _(otpStageName === 'otp_enter_phone', <OtpEnterNumber/>)
    const enterSmsContent = _(otpStageName === 'otp_sms', <OtpSms/>)

    // tablet
    if (windowWidth <= 1300) {
        return (
            <div className="initial-screen-wrapper">
                <div className="initial-screen-data-wrapper">

                    <div className="initial-screen-support-link-wrapper">
                        <SupportLink/>
                    </div>

                    <SelectLanguage/>

                    <div className="initial-screen-data-container">

                        <LogoIcon className="initial-screen-logo"/>

                        {enterPhoneContent}

                        {enterSmsContent}

                    </div>
                </div>

                {notificationComponent}

            </div>
        )
    }

    return (
        <div className="initial-screen-wrapper">
            <div className="initial-screen-data-wrapper">

                <div className="initial-screen-support-link-wrapper">
                    <SupportLink/>
                </div>

                <div className="initial-screen-data-container">

                    <LogoIcon className="initial-screen-logo"/>

                    {enterPhoneContent}

                    {enterSmsContent}

                </div>
            </div>

            <InitialScreenRightSide/>

            {notificationComponent}

        </div>
    )
}